import { LanguageService } from './../../services/language.service';
import { ProductsService } from './../../services/products.service';
import { StructuresService } from './../../services/structure.service';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Product } from '../../interfaces/product';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-extra',
    templateUrl: './catalogue.component.html',
    styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {

    structureId: string; // shorthand
    products: Product[];
    lang: string;

    constructor(
        private router: Router,
        private structureService: StructuresService,
        private productsService: ProductsService,
        private languageService: LanguageService
    ) { }

    private async init() {
        // this.products = await this.productsService.getProductsByStructureId(this.structureId).toPromise();

        this.structureId = this.structureService.getId();
        const fullList = await this.productsService.getProductsByStructureId(this.structureId).toPromise();
        this.products = fullList.filter((item: Product) => item.status == true);
        // this.products = this.products.map( (product: Product) => {
        this.products = this.products.map((product: Product) => {
            if (product.image.indexOf("//") != -1) {
                const items = product.image.split('/');
                product.image = environment.BASE_URL + '/images/' + items[4];
            } else {
                // console.log(product.image);
                product.image = environment.BASE_URL + product.image;
            }
            return product;
        });
    }

    ngOnInit() {
        this.languageService.currentLang.subscribe(lang => this.lang = lang);

        this.init();
        // this.productsService.getProductsByStructureId(this.structureId)
        //     .subscribe((products: Product[]) => {
        //         this.products = products;
        //     });
    }

    navigateToDetail(productId: string) {
        this.router.navigate([`/${this.structureId}/products/${productId}`]);
    }

}
