<app-breadcrumb *ngIf='logged' [error]="''" (submitLogout)='logout()'></app-breadcrumb>

<div class="component-container">
    <div class="mat-elevation-z9 p-3" *ngIf="( labels$ | async ) as labels">
        <div *ngIf="order">
            <h3 class="ordine">{{ labels['order'] | translate: lang }}</h3>
            <p class="ordine">{{order?.id}}</p>
            <!--     <h4>{{order?.struttura?.businessName}}</h4>
            <p>{{order?.timestamp| date: 'dd/MM/yyyy'}}</p>
            <p>{{order?.struttura.city}}</p>
            <p>{{getPrice(order.carrello)}} €</p>
            <hr> -->
            <div *ngIf="order.carrello.length > 0">
                <table class="p-3">
                    <tbody>
                        <tr *ngFor="let item of order.carrello" style="border: 1px;">
                            <td>
                                <div class="content-box">
                                    <img [src]="item.prodotto.image" alt="" class="image_service_featured">

                                    <div class="descrizione-servizio">
                                        <p class="nome-struttura"> {{order?.struttura?.businessName}}</p>
                                        <p class="data-servizio"> {{order?.timestamp| date: 'dd/MM/yyyy'}}</p>
                                        <p class="nome-city"> {{order?.struttura.city}}</p>
                                        <p class="costo-servizio">{{getPrice(order.carrello)}} €</p>
                                    </div>
                                    <div class="bottone-modifica">
                                        <button mat-icon-button (click)="openCartItem(order)">
                                            <mat-icon>create</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div>