
<div mat-dialog-content *ngIf="( data.labels$ | async ) as labels">
    <span *ngFor="let cartItem of data.item.carrello">
        <div class="stato-ordine">{{data.item.stato}}</div>
        <div>
            <img [src]="cartItem.prodotto.image" alt="">
        </div>
        <div>
            <h4 mat-dialog-title>
                {{cartItem.prodotto.name[data.lang]}}
            </h4>
            <p>
                {{cartItem.prodotto.short_description[data.lang]}}
            </p>
        </div>
        <div>
            <hr>
            <p>{{labels['reservation'][data.lang]}}:<br> {{cartItem.date_in}}</p>

            <p>{{labels['price'][data.lang]}}: <br>{{cartItem.totalPrice}} €</p>
            <hr>
            <p class="richiedi-cancellazione">{{labels['cancelrequest'][data.lang]}} :</p>
        </div>

    </span>
</div>
<div mat-dialog-actions *ngIf="( data.labels$ | async ) as labels">
    <button mat-raised-button (click)='close()'>{{labels['close'][data.lang]}}</button>
    <button mat-raised-button color="warn" [mat-dialog-close]="data" type="button"
        cdkFocusInitial>{{labels['remove'][data.lang]}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="data" type="button"
        cdkFocusInitial>{{labels['confirm'][data.lang]}}</button>
</div>