<!-- <h1 mat-dialog-title>I tuoi dati</h1> -->
<div class="component-container">
    <h4>I tuoi dati</h4>
    <form id="contact" action="">
        <!-- {{data|json}} -->
        <fieldset>
            <input placeholder="Nome" type="text" tabindex="1" required autofocus name="firstName" [(ngModel)]="data.firstName">
        </fieldset>
        <fieldset>
            <input placeholder="Cognome" type="text" tabindex="2" required name="lastName" [(ngModel)]="data.lastName">
        </fieldset>
        <fieldset>
            <input placeholder="Email" type="email" tabindex="3" required name="userName" [(ngModel)]="data.userName">
        </fieldset>
        <fieldset>
            <input placeholder="Telefono" type="tel" tabindex="4" required name="phone" [(ngModel)]="data.phone">
        </fieldset>
    </form>
    
    <div class="col-12">
        <button class="save-button" type="button" (click)='regUser()'>Invia</button>
        <button class="exit-button" mat-button (click)='close()'>Esci</button>
    </div>
</div>
<!-- <div mat-dialog-content> -->
<!-- This dialog showcases the title, close, content and actions elements. -->
<!--  <div>
        
    </div>
    <mat-form-field>
        <mat-label>Dettagli</mat-label>
        <input matInput [(ngModel)]="data.username">
    </mat-form-field>
    {{data.username}}
</div> -->

<!-- <div mat-dialog-actions> -->
<!-- <button class="save-button" mat-icon-button [mat-dialog-close]="true" type="button" cdkFocusInitial>Salva</button> -->
<!-- <button class="exit-button" mat-button (click)='close()'>Esci</button> -->

<!-- </div> -->