<div class="component-container">
    <h4>Vuoi cambiare password?</h4>
    <p> Inserisci il tuo indirizzo email e ti invieremo un link per reimpostarla</p>
    <form id="resetPass" action="">
        <mat-label>Email</mat-label>
        <input mat-input placeholder="Email" type="email" tabindex="1" required autofocus name="email" [(ngModel)]="data.mailbox">
    </form>

    <div class="col-12">
        <div class="alert alert-success" role="alert" *ngIf="showOkMsg">Link inviato. Grazie.</div>
        <div class="alert alert-danger" role="alert" *ngIf="showFailMsg">Operazione non eseguita. La mail indicata non è registrata.</div>
        <button class="save-button" mat-button (click)='callResetRequest()' *ngIf="!showOkMsg && !showFailMsg">Invia il link</button>
        <button class="exit-button" mat-button (click)='close()'>Esci</button>
    </div>

</div>


<!-- <h1 mat-dialog-title>Recupero password utente</h1>
<div mat-dialog-content>
    <div>
        
    </div>
    <mat-form-field>
        <mat-label>Dettagli</mat-label>
        <input matInput [(ngModel)]="data.username">
    </mat-form-field>
    {{data.username}}
</div>
<div mat-dialog-actions>
    <button mat-button (click)='close()'>Close</button>
    <button mat-icon-button [mat-dialog-close]="true" type="button" cdkFocusInitial>Ok</button>
</div> -->