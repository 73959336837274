import { GEVConf } from './../../interfaces/gev_conf';
import { QuestionSubarea } from '../../interfaces/question_subarea';
import { QuestionArea } from '../../interfaces/question_area';
import { AnswerType } from '../../interfaces/answer_type';
import { Component, OnInit } from '@angular/core';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { ActivatedRoute } from '@angular/router';
import { Questionnaire } from '../../interfaces/questionnaire';
import { Subject, Observable } from 'rxjs';
import { Question } from '../../interfaces/question';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Answer } from '../../interfaces/answer';
import { QuestionTypes } from '../../interfaces/question_types';
import { GEVSubmission } from '../../interfaces/submission';
import { LanguageService } from '../../services/language.service';
import { ComplexLabels } from '../../interfaces/complex_labels';
import { Label } from '../../interfaces/label';
import { StructuresService } from '../../services/structure.service';
@Component({
    selector: 'app-chat-component',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

    questionTypes = QuestionTypes;

    private _globalChecker = new Subject<{ [key: string]: boolean }>();
    private globalChecker$ = this._globalChecker.asObservable();
    private globalChecker = {
        questionnaire: false,
        questions: false,
        answerTypes: false
    };

    qId: string;
    labels: ComplexLabels;
    questionnaire: Questionnaire;
    questions: Question[];
    answerTypes: AnswerType[];
    currentIndex = -1;
    currentQuestion: Question;
    currentAnswers: Answer[];
    fullLoad = false;
    questionnaireEnded = false;
    submissionFormGroup: FormGroup;
    lang: string;

    currentSubmission: GEVSubmission;
    currentSubmissionId: string;

    smileValue: number;
    smileValues = [{
        icon: `☹️`,
        value: 1
    }, {
        icon: '😐',
        value: 2
    }, {
        icon: '😊',
        value: 3
    }];

    multiSmileShadow = {};

    structureId: string;

    private conf: GEVConf[];

    constructor(
        private qs: QuestionnaireService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private ls: LanguageService,
        private ss: StructuresService
    ) {
        this.questionnaire = {} as Questionnaire;
        this.conf = [
            {
                variable: this.questionnaire,
                method: this.qs.getQuestionnaire,
                service: this.qs,
                observable: this.qs.questionnaires$
            }
        ];
        this.structureId = this.ss.structureId;

        this.lang = this.ls.lang;

        if (this.ls.labels) {
            this.labels = this.ls.labels;
        } else {
            this.ls.labels$.subscribe((observer: ComplexLabels) => {
                this.labels = observer;
            });
        }
        this.globalChecker$.subscribe((newObj: any) => {
            Object.assign(this.globalChecker, newObj);
            if (!Object.values(this.globalChecker).includes(false)) {
                this.assignAnswerTypesToQuestions(this.questions, this.answerTypes);
            }
        });
        this.submissionFormGroup = this.fb.group({});

        if (this.qs.questionnaires) {
            this.questionnaire = this.qs.questionnaires[0];
            this.populateTheRest(this.questionnaire);
        } else {
            this.qs.getAllQuestionnaires().subscribe((qsts: Questionnaire[]) => {
                this.questionnaire = qsts[0];
                this.populateTheRest(this.questionnaire);
                this.qs.setQuestionnaires(qsts);
            }, err => {
                // TODO catch
            });
        }
    }

    setValueDate($event: Event): void {
        const selectedDate = $event.target['value'];
        const date = new Date(selectedDate);
        console.log('$event', selectedDate);
        console.log('$event', date);
        this.setValue(this.currentQuestion, null, date.toString());
    }

    populateTheRest(questionnaire: Questionnaire): void {
        this.qId = questionnaire.id;
        this.getAnswerTypes();
        this.getQuestionsForQuestionnaire(questionnaire.id);
    }

    ngOnInit() {
    }

    assignAnswerTypesToQuestions(questions: Question[], answerTypes: AnswerType[]): void {
        console.log('assignAnswerTypesToQuestions', answerTypes);
        questions.map((q: Question) => {
            q.UItiporisposta = answerTypes.find((at: AnswerType) => at.id === q.tiporispostaId).name;
        });
        this.fullLoad = true;
    }

    getAnswerTypes(): void {
        this.qs.getAnswerTypes().subscribe((ats: AnswerType[]) => {
            this.answerTypes = ats;
            this._globalChecker.next({ answerTypes: true });
        }, err => {
            console.log('err', err);
        });
    }

    getQuestionnaire(qId: string): void {
        this.qs.getQuestionnaire(qId).subscribe((q: Questionnaire) => {
            this.questionnaire = q;
            this._globalChecker.next({ questionnaire: true });
        }, err => {
            console.log(err);
        });
    }

    getQuestionsForQuestionnaire(qId: string, currentSubmission?: GEVSubmission): void {
        console.log('my qid', qId);
        this.qs.getQuestionsForQuestionnaire(qId)
            .subscribe((qs: Question[]) => {
                console.log('questions retrieved', qs);

                let filteringArea: string;

                if (currentSubmission) {
                    filteringArea = currentSubmission.questions.selectedAreaTypes;
                } else {
                    filteringArea = this.qs.currentlySelectedAreasOrSubareas.selected;
                }

                console.log('filteringArea', filteringArea);
                let itemsToFilter: Question[] = qs;
                let goodIds: string[] = [];
                if (currentSubmission) {
                    goodIds = currentSubmission.questions.selectedAreas.map((i) => i.id);
                } else {
                    goodIds = this.qs.currentlySelectedAreasOrSubareas.items.map((i) => i.id);
                }

                console.log('currentSubmission', currentSubmission);
                console.log('itemsToFilter', itemsToFilter);
                console.log('goodIds', goodIds);
                if (filteringArea === 'areas') {
                    itemsToFilter = qs.filter((q: Question) => {
                        return goodIds.includes(q.areaId);
                    });
                } else {
                    itemsToFilter = qs.filter((q: Question) => {
                        return goodIds.includes(q.sottoareaId);
                    });
                }

                // const newQuestions = itemsToFilter.sort(this.dynamicSort('position'));
                console.log('itemsToFilter', itemsToFilter);
                this.questions = itemsToFilter.length === 0 ? qs : itemsToFilter;
                console.log('THIS . QUESTIONS', this.questions);

                // DEBUG
                // this.currentIndex = this.questions.length - 2;
                // END DEBUG
                console.log('this.questions', this.questions);
                this.pickNextQuestion(this.questions, this.currentIndex);
                console.log(this.questions);
                this._globalChecker.next({ questions: true });
            }, err => {
                console.log(err);
            });
    }

    dynamicSort(property) {
        const sortOrder = 1;
        if (property[0] === '-') {
            property = property.substr(1);
        }
        return function (a, b) {
            const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        };
    }

    pickNextQuestion(questions: Question[], currentIndex: number): void {
        this.currentIndex++;

        const relatedQuestions: Question[] = this.questions;

        this.currentQuestion = questions[this.currentIndex];
        const formControl: FormControl = this.fb.control(null, Validators.required);
        this.submissionFormGroup.addControl(this.currentQuestion.id, formControl);
        this.getAnswersForQuestion(this.currentQuestion.id);

    }

    getAnswersForQuestion(questionId: string): void {
        this.qs.getAnswersForQuestion(questionId).subscribe((anss: Answer[]) => {
            this.currentAnswers = anss;
            console.log('ANSWERS RECEIVED', this.currentAnswers);
            this.currentAnswers.map((a: Answer) => {
                this.multiSmileShadow[a.id] = null;
            });
            this.assignAnswerTypesToQuestions(this.questions, this.answerTypes);
        }, err => {

        });
    }

    selectAnswer(answer): void {
        this.currentAnswers.map((a: Answer) => {
            a.selected = false;
        });
        answer.selected = true;
        this.setValue(this.currentQuestion, answer);
    }

    confirmAnswer(): void {
        console.log('fired nextquestion', this.currentAnswers);
        if (this.currentAnswers.some((a: Answer) => !!a.successivaId)) {
            const givenAnswer: string = this.submissionFormGroup.value[this.currentQuestion.id];
            const foundAnswer: Answer = this.currentAnswers.find((a: Answer) => a.id === givenAnswer[0]);
            if (foundAnswer.successivaId) {
                this.currentIndex = this.questions.indexOf(this.questions.find((q: Question) => {
                    return q.id === foundAnswer.successivaId;
                }));
            } else {
                this.currentIndex = this.currentIndex + 1;
            }
            console.log('given found answer', foundAnswer);
        } else {
            this.currentIndex = this.currentIndex + 1;
        }

        this.currentQuestion = this.questions[this.currentIndex];

        if (this.currentQuestion) {

            const formControl: FormControl = this.fb.control(null, Validators.required);
            this.submissionFormGroup.addControl(this.currentQuestion.id, formControl);

            this.qs.getAnswersForQuestion(this.currentQuestion.id)
                .subscribe((answers: Answer[]) => {
                    console.log('answers Received', answers);
                    this.currentAnswers = answers;
                });
        } else {
            const values = this.submissionFormGroup.value;
            console.log('questionario finito', values);
            this.questionnaireEnded = true;
        }
    }

    setValue(currentQuestion: Question, relatedAnswer: Answer, customValue?: string): void {
        if (currentQuestion.UItiporisposta === this.questionTypes.checkbox) {
            const value = this.submissionFormGroup.controls[currentQuestion.id].value;
            if (!value) {
                this.submissionFormGroup.controls[currentQuestion.id].patchValue([relatedAnswer.id]);
            } else {
                let newValue: string[] = value;
                if (value.includes(relatedAnswer.id)) {
                    newValue = value.filter((id: string) => id !== relatedAnswer.id);
                } else {
                    newValue.push(relatedAnswer.id);
                }
                this.submissionFormGroup.controls[currentQuestion.id].patchValue(newValue);
            }
        }

        if (currentQuestion.UItiporisposta === this.questionTypes.date) {
            this.submissionFormGroup.controls[currentQuestion.id].patchValue([customValue]);
        }

        if ((currentQuestion.UItiporisposta === this.questionTypes.radio) ||
            (currentQuestion.UItiporisposta === this.questionTypes.select_list)) {
            this.submissionFormGroup.controls[currentQuestion.id].patchValue([relatedAnswer.id]);
        }

    }

    selectCheckbox(ra: Answer): void {
        ra.selected = !ra.selected;
        this.setValue(this.currentQuestion, ra);
    }

    pickSmileValue(value: number, answerId: string): void {
        this.smileValue = value;
        console.log('answerId', answerId);
        console.log('value', value);
        const previousValue = this.submissionFormGroup.value[this.currentQuestion.id] || {};

        this.submissionFormGroup.controls[this.currentQuestion.id]
            .patchValue(Object.assign(previousValue, {
                [answerId]: value
            }));
        this.multiSmileShadow[answerId] = value;
    }

}
