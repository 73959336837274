<div class="product-container" *ngIf="structure && ( labels$ | async ) as labels">
    <mat-card class="product-card">
        <div>
            <button mat-fab aria-label="toggle directions" class="fullWBtn">
                <mat-icon class="scaleIcon-x2">location_on</mat-icon>
            </button>
        </div>
        <mat-card-header>
            {{ labels['address'] | translate: lang | uppercase}}
        </mat-card-header>
        <mat-card-content>
            {{structure.address}} {{structure.address2}} - {{structure.zip}}
            {{structure.city}}
        </mat-card-content>
    </mat-card>
    <mat-card class="product-card">
        <div>
            <button mat-fab aria-label="toggle directions" class="fullWBtn"
                (click)="makeCall(structure.businessPhoneNumber)">
                <mat-icon class="scaleIcon-x2">call</mat-icon>
            </button>
        </div>
        <mat-card-header>
            {{ labels['phone'] | translate: lang | uppercase}}
        </mat-card-header>
        <mat-card-content>
            {{structure.businessPhoneNumber}}
        </mat-card-content>
    </mat-card>
    <mat-card class="product-card">
        <div>
            <button mat-fab aria-label="toggle directions" class="fullWBtn" (click)="sendMail()">
                <mat-icon class="scaleIcon-x2">email</mat-icon>
            </button>
            <mat-card-header>
                EMAIL
            </mat-card-header>
            <mat-card-content>
                {{structure.representativeEmail}}
            </mat-card-content>
        </div>
    </mat-card>
</div>