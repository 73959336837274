import { Pipe, PipeTransform } from '@angular/core';
import { Attributo } from '../interfaces/attributo';
import { Varianti } from '../interfaces/varianti';

@Pipe({
  name: 'custom'
})
export class CustomPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
  transform(qta: number, fasce: Varianti[], ...args: unknown[]): Attributo {
    return fasce.length > 0 && fasce[0].attributi.filter((item: Attributo) => {
      return item.sconto;
    })[0];
  }

}
