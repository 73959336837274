import { CategoryProduct } from './../interfaces/category';
import { Pipe, PipeTransform } from '@angular/core';
/**
 * Questo pipe traduce le categorie, deve tradurre un `TranslationEntry`.
 */
@Pipe({name: 'translatePipe'})
export class CategoryPipe implements PipeTransform {
  transform(category: CategoryProduct, myLang: string): string {

    if ((!category.name[myLang]) || (category.name[myLang] === '')) {
      return category.name.en;
    } else {
      return category.name[myLang];
    }
  }
}
