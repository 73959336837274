import { TranslationEntry } from './../interfaces/translation_entry';
import { Subject } from 'rxjs';
// import { DOMAIN } from './../constants/strings';
import { environment } from '../../environments/environment';
import { CategoryProduct } from './../interfaces/category';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../app.component';

@Injectable()
export class NotificationsService {

    currentPage = new Subject<TranslationEntry>();
    newPage$ = this.currentPage.asObservable();

    constructor(
        public snackBar: MatSnackBar
    ) { }

    openSnackBar(msg: string): void {
        this.snackBar.open(msg, '', {
            duration: 1000
        });
    }

    setNewCurrentPage(page: TranslationEntry): void {
        this.currentPage.next(page);
    }

}
