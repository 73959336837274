<ng-container *ngIf="labels">
<form [formGroup]="submissionFormGroup">
  <ng-container *ngIf="fullLoad">

    <ng-container *ngIf="currentQuestion">
      <div class="current">
        <div class="question">
          <div class="number">
            <span *ngIf="!currentQuestion.isLast">{{currentIndex + 1}}.</span>
            {{currentQuestion.text | translate: lang}}
          </div>
          <div class="answer-wrap" *ngIf="currentAnswers">
            <ng-container [ngSwitch]="currentQuestion.UItiporisposta">
              <ng-container *ngSwitchCase="'multi_check'">

              </ng-container>
              <ng-container *ngSwitchCase="'multi_smile'">

                <div class="multi-smile-container">
                  <div class="smile-answer" *ngFor="let answer of currentAnswers">
                    <div class="multi-smile">
                      <div class="smile answer">
                        {{answer.text[lang]}}
                      </div>
                      <div
                      class="smile"
                      *ngFor="let sv of smileValues"
                      (click)="pickSmileValue(sv.value, answer.id)"
                      [ngClass]="{'selected': multiSmileShadow[answer.id] === sv.value}"
                      >
                        {{sv.icon}}
                      </div>
                    </div>
                  </div>

                </div>

              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <div class="date-container">
                  <input type="date" (change)="setValueDate($event)">
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'checkbox'">
                <div class="checkbox-list">
                  <div class="header">{{labels['selectMoreOptions'] | translate: lang}}</div>

                  <div class="checkbox-list-container">
                    <div (click)="selectCheckbox(ra)" class="checkbox-row" *ngFor="let ra of currentAnswers">
                      <div class="square-container">
                        <div class="checkbox" [ngClass]="{'selected': ra.selected}"></div>
                      </div>
                      <div class="text-container">
                        {{ra.text | translate: lang}}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'select_list'">
                <div class="select-list">
                  <div class="header">
                    {{labels['selectOneOption']| translate: lang}}
                  </div>
                  <div class="options">
                    <div
                    [ngClass]="{'selected': ra.selected}"
                    (click)="selectAnswer(ra, currentQuestion)" class="option" *ngFor="let ra of currentAnswers">
                      <div class="text-wrapper">
                        {{ra.text | translate: lang}}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'radio'">
                <div
                class="radio"
                *ngFor="let answer of currentAnswers"
                (click)="selectAnswer(answer)"
                [ngClass]="{'selected': answer.selected}">
                  <div class="radio-content">
                    <div class="content-flex">
                      {{answer.text | translate: lang}}
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div class="end" *ngIf="currentQuestion.isLast">
            <img src="assets/happy.jpg" alt=""><br />

            <a [routerLink]="['/' + structureId]">{{labels['backHome']| translate: lang}}</a>
          </div>
          <div class="confirm-wrap" *ngIf="!currentQuestion.isLast">
            <button
            mat-raised-button
            [disabled]="submissionFormGroup.invalid"
            (click)="confirmAnswer()"
            type="button"
            class="btn btn-submit"
            color="primary"
            >
              {{labels['confirm'] | translate: lang}}
            </button>
          </div>
        </div>

      </div>
    </ng-container>

    <ng-container *ngIf="questionnaireEnded">
      <h3 class="thanks">{{labels['questionnaireEnded']| translate: lang}}</h3>
      <div class="end">
        <img src="assets/happy.jpg" alt=""><br />

        <a [routerLink]="['/' + structureId]">{{labels['backHome']| translate: lang}}</a>
      </div>

    </ng-container>
  </ng-container>
  </form>
</ng-container>
