// import { AgmCoreModule } from '@agm/core';
import { HowToArriveComponent } from './how-to-arrive/how-to-arrive.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslationModule } from './translation.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { NotificationsService } from '../services/notifications.service';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        TranslationModule,
        MatDividerModule,
        MatSelectModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        GoogleMapsModule
        // AgmCoreModule.forRoot({
        //     apiKey: 'AIzaSyD7CQl6fRhagGok6CzFGOOPne2X1u1spoA'
        // }),,
    ],
    declarations: [
        HowToArriveComponent
    ],
    providers: [

    ]
})
export class HowToArriveModule { }
