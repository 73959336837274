import { Pipe, PipeTransform } from '@angular/core';
import { Attributo } from '../interfaces/attributo';
import { Varianti } from '../interfaces/varianti';

@Pipe({
  name: 'quantity'
})
export class QuantityPipe implements PipeTransform {

  // transform(qta: number, ...args: unknown[]): unknown {
  transform(qta: number, fasce: Varianti[], ...args: unknown[]): Attributo {
    return fasce.length > 0 && fasce[0].attributi.filter((item: Attributo) => {
      return ((qta >= item.min_val) && (qta <= item.max_val));
    })[0];
  }
}
