import { Product } from './product';

export interface Person {
    age?: number;
    associatedPrice: number;
}

export class CartItem {
    productId: string;
    structureId?: string;
    people: Person[];
    totalPrice: number;
    prodotto?: Product;
    date_in?: Date;
    date_out?: Date;
    id: number;
    calcItemPrice() {
        return this.people.reduce((a, b) => a + b.associatedPrice, 0);
    }
}
