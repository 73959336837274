import { HowToArriveModule } from './modules/how-to-arrive.module';

import { ReadableColorPipe } from './pipes/colors.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { RoutingModule, appRoutes } from './routing/routing.module';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import {
    UserRegistrationDialog,
    PasswordRecoveryDialog,
} from './components/login-form/login-form.component';
import { CartItemDialog } from './components/cart/cart.component';
import { OrderItemDialog } from './components/order-detail/order-detail.component';
import { DialogPassword } from './components/profile/profile.component';
/* SERVICES */
import { CategoriesService } from './services/categories.service';
import { NotificationsService } from './services/notifications.service';
import { ServiceWorkerModule } from '@angular/service-worker';

/* MATERIAL */
import { MatButtonModule } from '@angular/material/button';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StructuresService } from './services/structure.service';
import { LanguageService } from './services/language.service';
import { ProductsService } from './services/products.service';

// import { environment } from '../environments/environment';

import { ChatModule } from './modules/chat.module';
import { WeatherModule } from './modules/weather.module';
import { GuestsServicesModule } from './modules/guests-services.module';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { QuestionnaireService } from './services/questionnaire.service';

import { NgxStripeModule } from 'ngx-stripe';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { LandingResetComponent } from './components/landing-reset/landing-reset.component';
import { QuantityPipe } from './pipes/quantity.pipe';
import { PeriodPipe } from './pipes/period.pipe';
// import { ContactComponent } from './components/contact/contact.component';
import { CustomPipe } from './pipes/custom.pipe';
// import { LoginFormComponent } from './components/login-form/login-form.component';

@NgModule({
    declarations: [
        AppComponent,
        ReadableColorPipe,
        UserRegistrationDialog,
        PasswordRecoveryDialog,
        DialogPassword,
        CartItemDialog,
        OrderItemDialog,
        LandingResetComponent,
        QuantityPipe,
        PeriodPipe,
        // ContactComponent,
        CustomPipe,
    ],
    entryComponents: [
        UserRegistrationDialog,
        PasswordRecoveryDialog,
        DialogPassword,
        CartItemDialog,
        OrderItemDialog,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatFormFieldModule,
        MatIconModule,
        MatListModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatCardModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatMenuModule,
        HowToArriveModule,
        RoutingModule,
        ChatModule,
        WeatherModule,
        GuestsServicesModule,
        // environment.production ? ServiceWorkerModule.register('/ngsw-worker.js') : [],
        HttpClientModule, HttpClientJsonpModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxStripeModule.forRoot('pk_test_ioIcsMFUtY2X3AWz7RevmQeO'),
        // pk_test_ioIcsMFUtY2X3AWz7RevmQeO
        // pk_test_jeGx4I8NLsLjVqbjoSOBC2qF
        RouterModule.forRoot(appRoutes, {
            enableTracing: false,
            relativeLinkResolution: 'legacy',
        })
    ],
    providers: [
        CategoriesService,
        NotificationsService,
        StructuresService,
        LanguageService,
        ProductsService,
        QuestionnaireService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
