import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Product } from '../interfaces/product';
import { Order } from '../interfaces/order';
import { Varianti } from '../interfaces/varianti';
import { Attributo } from '../interfaces/attributo';
import { PriceRange } from './../interfaces/price_range';
import { StructuresService } from './structure.service';
import { Structure } from '../interfaces/structure';
import { Calendario } from '../interfaces/calendario';

@Injectable()
export class ProductsService {

    constructor(
        private http: HttpClient,
        private structuresService: StructuresService
    ) { }

    getPriceRangesForProduct(productId: string, structureId: string): Observable<PriceRange[]> {
        const textFiter = `?[where][strutturaId]=${structureId}`;
        return this.http.get<PriceRange[]>(`${environment.DOMAIN}/Prodotti/${productId}/prezzi${textFiter}`);
    }

    getVariazioni(listinoId: string): Observable<Varianti[]> {
        // ?filter[include]=attributi&filter[include]=gruppo&filter[where][listinoId]=5f8419305cf3234678992662
        const textFiter = `?filter[include]=attributi&filter[include]=gruppo&filter[include]=prezzo&filter[where][listinoId]=${listinoId}`;
        return this.http.get<Varianti[]>(`${environment.DOMAIN}/varianti-listino${textFiter}`);
    }

    getCalendario(listinoId: string, _date: Date): Observable<Calendario[]> {
        const textFiter = `?filter[where][listinoId]=${listinoId}&filter[where][date_from][lte]=${_date}&filter[where][date_to][gte]=${_date}`;
        return this.http.get<Calendario[]>(`${environment.DOMAIN}/calendari${textFiter}`);
    }

    getCalendarioList(listinoId: string, _date: string): Observable<Calendario[]> {
        const textFiter = `?filter[where][listinoId]=${listinoId}&filter[where][date_to][gte]=${_date}`;
        return this.http.get<Calendario[]>(`${environment.DOMAIN}/calendari${textFiter}`);
    }

    getProductsFromCategoryId(structureId: string, categoryId: string, listIdGestori: string[]): Observable<Product[]> {
        listIdGestori.push(structureId);
        const listIdGStr = JSON.stringify(listIdGestori)
        const url = `${environment.DOMAIN}/CategorieProdotto/${categoryId}/prodotti?filter={"where": {"strutturaId": {"inq": ${listIdGStr}}}}`;
        return this.http.get<Product[]>(url);

    }


    getAllProducts(): Observable<Product[]> {
        return this.http.get<Product[]>(`${environment.DOMAIN}/Prodotti`);
    }

    getProductById(productId: string): Observable<Product> {
        return this.http.get<Product>(`${environment.DOMAIN}/Prodotti/${productId}`);
    }

    getProductsByStructureId(structureId: string): Observable<Product[]> {
        return this.http.get<Product[]>(`${environment.DOMAIN}/Prodotti?filter={"strutturaId": "${structureId}"}`);
    }

    purchaseProduct(order: Order): Observable<Order> {
        return this.http.post<Order>(`${environment.DOMAIN}/Ordini`, order);
    }

}
