import { Injectable } from '@angular/core';
import { zip } from 'rxjs';
import { puts } from 'util';
import { CartItem } from '../interfaces/cart_item';
// import { StructuresService } from './structure.service';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    items: CartItem[];
    totItems: number;

    constructor() {
        this.items = this.getItems();
        this.totItems = this.items.length;
        localStorage.setItem('cits', JSON.stringify(this.items));
    }

    typeingCart(items: CartItem[]) {
        const itemsToReturn: CartItem[] = [];
        for (const iterator of items) {
            const d = new CartItem();
            Object.assign(d, iterator);
            itemsToReturn.push(d);
        }
        return itemsToReturn;
    }

    calcTotalPrice(items?: CartItem[]): number {
        const cartItems = items ? items : this.getItems();
        if (cartItems && cartItems.length > 0) {
            return cartItems.reduce((a, b) => a + b.calcItemPrice(), 0);
        }
        return 0;
    }

    getItems(): CartItem[] {
        const cits = localStorage.getItem('cits');
        const out: CartItem[] = [];
        if (cits) {
            const jcits = JSON.parse(cits);
            for (const jcit of jcits) {
                const d = new CartItem();
                Object.assign(d, jcit);
                if (!d. id) {
                    d.id = +out.length + 1;
                }
                out.push(d);

                // console.log(d.structureId, this.structureService.getId())
                // if (this.structureService.getId() && d.structureId == this.structureService.getId()){
                // }
            }
        }
        return out;
    }

    getItem(id: number): CartItem {
        const cits = localStorage.getItem('cits');
        const out: CartItem[] = [];
        if (cits) {
            const jcits = JSON.parse(cits);
            for (const jcit of jcits) {
                const d = new CartItem();
                Object.assign(d, jcit);
                out.push(d);
            }
        }
        const filter = out.find((x) => x.id === +id);
        return filter;
    }

    updateItem(cartItem: CartItem): void {
        const cits = localStorage.getItem('cits');
        const out: CartItem[] = [];
        if (cits) {
            const jcits = JSON.parse(cits);
            for (const jcit of jcits) {
                const d = new CartItem();
                Object.assign(d, jcit);
                if (d.id === cartItem.id) {
                    out.push(cartItem);
                } else {
                    out.push(d);
                }
            }
        }
        this.updateCart(out);
    }

    addItem(ci: CartItem): boolean {
        ci.id = +this.items.length + 1;
        this.items.push(ci);
        this.totItems = this.items.length;
        localStorage.setItem('cits', JSON.stringify(this.items));
        return true;
    }

    delItem(ci: CartItem): boolean {
        const removeIndex = this.items
            .map(function (item) {
                return item.id;
            })
            .indexOf(ci.id);
        // remove object
        const r = this.items.splice(removeIndex, 1);
        this.updateCart(this.items);
        return r.length > 0 ? true : false;
    }

    updateCart(cits: CartItem[]): CartItem[] {
        this.items = cits;
        this.totItems = this.items.length;
        localStorage.setItem('cits', JSON.stringify(cits));
        return cits;
    }

    wasteCart(): boolean {
        this.items = [];
        this.totItems = this.items.length;
        localStorage.setItem('cits', JSON.stringify(this.items));
        return true;
    }

    getGranTot(): number {
        let gtot = 0;
        for (const iterator of this.items) {
            const val = iterator.totalPrice;
            gtot += val;
        }
        return gtot;
    }
}
