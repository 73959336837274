<mat-card *ngIf="!products || products.length === 0">
    <div class="no-products" *ngIf='labels'>
        {{labels['noProductsForCategory'] | translate: lang}}
    </div>
</mat-card>

<div *ngIf="products" class="products">
    <div class="product pointer" *ngFor="let product of products" [ngStyle]="{
    'background-image': 'url(' + product.image + ')'
  }" (click)="goToProduct(product.id)">
        <div class="description">
            <h3>
                {{product.name | translate: lang}}
            </h3>
            <div class="price-box">
                <div class="desc">
                    {{product.short_description | translate: lang}}
                </div>
                <div class="price" *ngIf="product.special_price > 0">
                    <div>{{product.base_price}}€</div>
                    {{product.special_price}}€
                </div>
            </div>

        </div>



    </div>
</div>