<div class="bg-holder">
  <ng-container *ngIf="currentWeatherEntry">
    <ng-container *ngIf="currentWeatherEntry.weather[0].icon === '01d'">
      <video autoplay loop src="assets/rain.mp4"></video>
      <div class="resume-container">
        <div class="weather-field temperature">
          <div class="label">
            Temperatura
          </div>
          <div class="value">
            {{currentWeatherEntry.temp}} °C
          </div>
        </div>
        <div class="weather-field humidity">
          <div class="label">
            Umidità
          </div>
          <div class="value">
            {{currentWeatherEntry.main.humidity}}%
          </div>
        </div>
        <div class="weather-field hours">
          <div class="label">
            Orario
          </div>
          <div class="value">
            {{currentWeatherEntry.date.getHours()}}:{{currentWeatherEntry.date.getMinutes()}}
          </div>
        </div>

        <div class="weather-field hours">
            <div class="label">
              <mat-icon>chevron_left</mat-icon>
            </div>
            <div class="value">
              <mat-icon>chevron_right</mat-icon>
            </div>
          </div>

      </div>
    </ng-container>
  </ng-container>
</div>
