<!-- <p>landing-reset works!</p> -->
<mat-card *ngIf="!submitted || f.password02.errors">
    <mat-card-title>Modulo reimpostazione password</mat-card-title>
    <mat-card-content>
        <form [formGroup]="resetPassForm" (ngSubmit)="onSubmit()">
            <label for="password01">Password: </label>
            <input id="password01" type="text" formControlName="password01" required autofocus>

            <label for="password02">Conferma la password: </label>
            <input id="password02" type="text" formControlName="password02" required>
            <div *ngIf="submitted && f.password02.errors" class="invalid-feedback">
                <div *ngIf="f.password02.errors.required">Confirm Password is required</div>
                <div *ngIf="f.password02.errors.mustMatch">Passwords must match</div>
            </div>
            <!-- <p>Complete the form to enable button.</p> -->
            <p>
                <button type="submit" [disabled]="!resetPassForm.valid">Submit</button>
            </p>
        </form>

        <hr>

        <pre>{{ statusMessage }}</pre>
    </mat-card-content>
</mat-card>
<mat-card *ngIf="submitted && !f.password02.errors">
    <mat-card-title>Reimpostazione password eseguita</mat-card-title>
    <mat-card-content>
        <p>Grazie</p>
        <p>
            <a [href]="homeLink">Torna alla home</a>
        </p>
        
    </mat-card-content>
</mat-card>
