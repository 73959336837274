import { Component, Inject, OnInit } from '@angular/core';
import { Client } from '../../interfaces/client';
import { ClienteService } from '../../services/cliente.service';
import { UserService } from '../../services/user.service';
import {
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { User } from '../../interfaces/user';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../modules/must-match.validator';
import { LanguageService } from '../../services/language.service';

class UserObj {
    username: string;
    password: string;
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class ProfileComponent implements OnInit {
    currentCliente: Client;
    activation: Observable<string>;
    showActivation = false;
    activateForm: FormGroup;
    labels$: Observable<any>;
    lang: string;
    constructor(
        private userService: UserService,
        private languageService: LanguageService,
        private clientService: ClienteService,
        private _adapter: DateAdapter<any>,
        public dialog: MatDialog,
        private ar: ActivatedRoute,
        private formBuilder: FormBuilder
    ) {
        this.activation = this.ar.queryParamMap.pipe(map((params) => params.get('n') || ''));
        this.activation.subscribe((a) => {
            if (a.length > 0) {
                this.showActivation = true;
            } else {
                this.showActivation = false;
            }
        });
        this.activateForm = this.formBuilder.group(
            {
                password: ['', [Validators.required, Validators.minLength(6)]],
                confirmPassword: ['', Validators.required],
            },
            {
                validator: MustMatch('password', 'confirmPassword'),
            }
        );
    }
    get f() {
        return this.activateForm.controls;
    }

    french() {
        this._adapter.setLocale('fr');
    }

    async getCurrentCliente() {
        const cli: Client[] = await this.clientService.getCliente().toPromise();
        if (cli && cli.length > 0) {
            this.currentCliente = cli[0];
            // console.log(cli[0]);
        }
    }

    logged(): boolean {
        const u = this.userService.getCurrentUser();
        if (u) {
            return true;
        }
        return false;
    }

    async log(e: UserObj) {
        // const U = await this.userService.login(e.username, e.password).toPromise();
        this.getCurrentCliente();
    }

    logout() {
        localStorage.removeItem(window.btoa('currentUser'));
    }

    async updateCliente() {
        const cliente: Client = await this.clientService
            .updateCliente(this.currentCliente)
            .toPromise();
        console.log(cliente);
    }

    setLang(ln:string) {
        this.languageService.setLang(ln);
        this.lang = this.languageService.lang;
    }

    LANGS = [
        {
            iso: 'it',
            img: 'https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Italy.svg',
            description: 'Italian',
        },
        {
            iso: 'en',
            img: 'https://upload.wikimedia.org/wikipedia/commons/0/0b/English_language.svg',
            description: 'English',
        },
        {
            iso: 'fr',
            img:
                'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_France_%281790%E2%80%931794%29.svg',
            description: 'French',
        },
        {
            iso: 'es',
            img:
                'https://upload.wikimedia.org/wikipedia/commons/b/bf/Simplified_Flag_of_Spain_%28civil_variant%29.svg',
            description: 'Spanish',
        },
        {
            iso: 'de',
            img: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg',
            description: 'German',
        },
        {
            iso: 'zh-CHS',
            img:
                'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg',
            description: 'Simplified Chinese',
        },
    ];
    
    getCurrentLangFlag(): any {
        const r = this.LANGS.find(x => x.iso === this.lang);
        return r;
    }

    getFlag(iso: string) {
        return this.LANGS.find(x => x.iso === iso);
    }

    ngOnInit(): void {
        this.currentCliente = {} as Client;
        if (this.logged) {
            this.getCurrentCliente();
        }
        this.labels$ = this.languageService.retrieveLabels2();
        this.languageService.currentLang.subscribe(lang => this.lang = lang)
    }

    applyFilter(filterValue: string) { }

    openDialog(): void {
        // tslint:disable-next-line: no-use-before-declare
        const dialogRef = this.dialog.open(DialogPassword, {
            width: '250px',
            data: this.userService.getCurrentUser(),
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            console.log('The dialog was closed', result);
            const user = this.userService.getCurrentUser();
            user.password = result;
            try {
                await this.userService.updateUser(user).toPromise();
                // notifica ok
                alert('Operazione completata con successo.');
            } catch (error) {
                alert('Errore: operazione non eseguita.');
            }
        });
    }
}

@Component({
    selector: 'app-dialog-password',
    templateUrl: 'dialog-password.html',
})
// tslint:disable-next-line: component-class-suffix
export class DialogPassword {
    pass1: string;
    pass2: string;
    constructor(
        public dialogRef: MatDialogRef<DialogPassword>,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public user: User
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
    // regUser() {
    //     this.user.password = this.pass1;
    //     this.userService.updateUser(this.user).toPromise();
    //     console.log(this.user);
    // }
    close() {
        // this.regUser();
        this.dialogRef.close();
    }
}
