<mat-grid-list cols="2" rowHeight="12rem" *ngIf="products">
    <mat-grid-tile *ngFor="let product of products" (click)="navigateToDetail(product.id)" class="pointer">
        <img [src]="product.image" alt="">
        <div class="descriptive">
            <div class="title">
                {{product.name | translate: lang}}
            </div>
            <div class="price">
                <SPAN *ngIf="product.special_price > 0">
                    {{product.special_price}}€
                </SPAN>
            </div>
        </div>

    </mat-grid-tile>
</mat-grid-list>