<mat-card>
    <mat-card-title>{{ labels['signin'] | translate: lang}}</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="submit()">
           
            <input type="text" matInput placeholder="E-mail" formControlName="username">
            <input type="password" matInput placeholder="Password" formControlName="password">

            <p *ngIf="error" class="error">
                {{ error }}
            </p>
            <div class="col-12">
                <button class="button-login" type="submit">{{ labels['signin'] | translate: lang}}</button>
            </div>
          <!--   <div class="button">
                <button type="submit" mat-button>Login</button>
            </div> -->
            <!-- {{included}} -->
            <p class="register-password" *ngIf="!included">
                <a mat-button href="javascript:void(0)" (click)='openDialogRegister()'>{{ labels['userRegistration'] | translate: lang}}</a>
            </p>
            <p class="register-password">
                <a mat-button href="javascript:void(0)" (click)='openDialogPassRecovery()'>{{ labels['userPassLost'] | translate: lang}}</a>
            </p>

            <p *ngIf="registering" class="error">
                La procedura di registrazione è stata eseguita. Riceverà un mail con istruzioni per completare il processo.
            </p>
        </form>
    </mat-card-content>
</mat-card>