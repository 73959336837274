import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { User } from '../interfaces/user';
import { StructuresService } from '../services/structure.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private userUrl: string;
    private apiLogin: string;
    apiLogout: string;
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;


    constructor(private http: HttpClient, private structureService: StructuresService, public router: Router) {
        this.userUrl = environment.SERVER_USER_PATH;
        this.apiLogin = environment.SERVER_LOGIN_PATH;
        this.apiLogout = environment.SERVER_LOGOUT_PATH;
        if (localStorage.getItem(window.btoa('currentUser'))) {
            this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(window.atob(localStorage.getItem(window.btoa('currentUser')))));
        } else {
            this.currentUserSubject = new BehaviorSubject<User>(null);
        }
        this.currentUser = this.currentUserSubject.asObservable();
    }

    getCurrentUser(): User {
        const ua = localStorage.getItem(window.btoa('currentUser'));
        if (!ua) {
            return null;
        }
        const payload = window.atob(ua);
        return JSON.parse(payload);
    }

    login(username: string, password: string) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = `{ "email": "${username}", "password": "${password}" }`;
        return this.http.post<any>(this.apiLogin, body, options)
            .pipe(
                map(user => {
                    // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                    user.authdata = window.btoa(username + ':' + password);
                    user.email = username;
                    const payload = window.btoa(JSON.stringify(user));
                    localStorage.setItem(window.btoa('currentUser'), payload);
                    // this.global.setLanguage('it');
                    this.currentUserSubject.next(user);
                    return user;
                }), catchError(err => {
                    console.log('Handling error locally and rethrowing it...', err);
                    return throwError(err);
                })
            );
    }


    logout() {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const token = this.getCurrentUser().token;
        return this.http.get<any>(this.apiLogout + '?access_token=' + token, options)
            .pipe(
                map((entity) => {
                    localStorage.removeItem(window.btoa('currentUser'));
                    const structureId = this.structureService.getId();
                    this.router.navigate([`/${structureId}/profile`]);
                    return entity;
                }),
                catchError((err) => {
                    localStorage.removeItem(window.btoa('currentUser'));
                    return err;
                })
            );
    }

    updateUser(user: User): Observable<User> {
        // console.log(`${this.userUrl}`);
        // const currentUser: User = this.getCurrentUser();
        user.id = user.userId;
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.patch<any>(`${this.userUrl}?access_token=${user.token}`, user, options);
    }

    registerUser(user: User): Observable<User> {
        // console.log(`${this.userUrl}`);
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<any>(`${this.userUrl}`, user, options);
    }

    resetRequest(mailbox: string) {
        // /mini-user/reset-request
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const resetRequestLink = `${this.userUrl}/reset-request`;
        const payload = {
            email: mailbox
        }
        return this.http.post<any>(resetRequestLink, payload, options);
    }

    resetExec(mailbox: string, challenge: string, hash: string, pass: string) {
        // - token / challenge
        // - nuova password
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const resetRequestLink = `${this.userUrl}/reset-execute`;
        const payload = {
            email: mailbox,
            challenge,
            pass
        }
        return this.http.post<any>(resetRequestLink, payload, options);
    }

}
