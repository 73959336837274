import { Client } from './client';
import { Structure } from './structure';

export class User {
    id?: string;
    email?: string;
    password?: string;
    userName?: string;
    firstName?: string;
    lastName?: string;
    userId?: string;
    authdata?: string;
    strutturaId?: string;
    token?: string;
    strutture?: Array<Structure>;
    phone?: string;
    cliente?: Client
}
