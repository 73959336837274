import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    @Output() submitLogout = new EventEmitter<boolean>();

    constructor(private userService: UserService) { }

    logout() {
        this.userService.logout().subscribe(x => console.log(x));
        // localStorage.removeItem(window.btoa('currentUser'));
        this.submitLogout.emit(false);
    }

    ngOnInit(): void {
    }

}
