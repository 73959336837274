import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
import { Order } from '../../interfaces/order';
import { OrdersService } from '../../services/orders.service';
import { UserService } from '../../services/user.service';
import { CartService } from '../../services/cart.service';
import { StructuresService} from '../../services/structure.service';
// MatHeaderRowDef,
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table';
import { CartItem } from '../../interfaces/cart_item';
import { Router } from '@angular/router';
import { LanguageService } from '../../services/language.service';
// import { iterator } from 'rxjs/internal-compatibility';

export interface UserData {
    id: string;
    name: string;
    progress: string;
    color: string;
}
/** Constants used to fill up our data base. */
const COLORS: string[] = ['maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple',
    'fuchsia', 'lime', 'teal', 'aqua', 'blue', 'navy', 'black', 'gray'];
const NAMES: string[] = ['Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack',
    'Charlotte', 'Theodore', 'Isla', 'Oliver', 'Isabella', 'Jasper',
    'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'];

class UserObj {
    username: string;
    password: string;
}

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
    allOrders: Order[];
    displayedColumns: string[] = ['name', 'progress', 'timestamp', 'carrello', 'azioni'];
    dataSource: MatTableDataSource<Order>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    logged: boolean;
    labels$: any;
    lang: string;
    constructor(
        private languageService: LanguageService,
        private ordersService: OrdersService,
        private userService: UserService,
        private cartService: CartService,
        private notificationsService: NotificationsService,
        private structureService: StructuresService,
        private router: Router
    ) { }

    openOrder(item: Order) {
        // console.log(item);
        const structureId = this.structureService.getId();
        this.router.navigate([`/${structureId}/order-detail/${item.id}`]);
    }

    getPrice(object: CartItem[]): number {
        // items = Object.assign(items)
        /**
         * gli items provengono dal template che a sua volta li ha presi
         * dalla chiamata http, quindi non possoggono il type e sono
         * obbligato a fre questo ciclo per eseguire su ciascuno un Object.assign che 
         * conferirà tutte le proprietà ed i metodi agli oggetti
         * 
         * sarà il caso di farne una utility condivisa
         */
        const itemsToCalc: CartItem[] = [];
        for (const iterator of object) {
            const d = new CartItem();
            Object.assign(d, iterator);
            itemsToCalc.push(d);
        }
        return this.cartService.calcTotalPrice(itemsToCalc);
    }

    isLogged(): boolean {
        const u = this.userService.getCurrentUser();
        if (u) {
            return true;
        }
        return false;
    }

    logout() {
        setTimeout(() => {
            this.allOrders = [];
            this.dataSource = new MatTableDataSource(this.allOrders);
            this.logged = false;
        }, 50);
    }


    async getAllOrders() {
        const allOrders: Order[] = await this.ordersService.getOrders().toPromise();
        this.allOrders = allOrders;
        this.dataSource = new MatTableDataSource(allOrders);
        this.logged = this.isLogged();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }
    }

    ngOnInit(): void {
        this.languageService.currentLang.subscribe( lang => this.lang = lang );
        this.labels$ =  this.languageService.retrieveLabels2();
        this.notificationsService.setNewCurrentPage({ it: 'Il mio StayMore', en: 'How to Arrive' });
        this.getAllOrders();
    }


}
