import { Subject ,  Observable } from 'rxjs';
import { QuestionSubarea } from './../interfaces/question_subarea';
import { AnswerType } from './../interfaces/answer_type';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Questionnaire } from '../interfaces/questionnaire';
import { Question } from '../interfaces/question';
import { Answer } from '../interfaces/answer';
import { QuestionArea } from '../interfaces/question_area';
import { GEVSubmission } from '../interfaces/submission';


@Injectable()
export class QuestionnaireService {

  ACCESS_TOKEN = 'xJstLl6XSPIvAlRQD39H4sNplPPzX2bfmmGwwAMeMxryRhigxQuTm4vpnPQ4G594';

  BASE_URL = `http://gev.greenconsulting.it/api`;

  public questionnaires: Questionnaire[];
  private _questionnaires = new Subject<Questionnaire[]>();
  public questionnaires$ = this._questionnaires.asObservable();

  currentlySelectedAreasOrSubareas = {
    selected: null,
    items: []
  };

  constructor(
    private http: HttpClient
  ) {}

  setCurrentSelection(selectedField: string, items: QuestionArea[]|QuestionSubarea[]): void {
    Object.assign(this.currentlySelectedAreasOrSubareas, {
      selected: selectedField,
      items: items
    });
    console.log('this.currentlySelectedAreasOrSubareas', this.currentlySelectedAreasOrSubareas);
  }

  retrieveSubmission(submissionId: string): Observable<GEVSubmission> {
    return this.http.get<GEVSubmission>(`${this.BASE_URL}/Somministrazioni/${submissionId}`);
  }

  createSubmission(submission: GEVSubmission): Observable<GEVSubmission> {
    return this.http.post<GEVSubmission>(`${this.BASE_URL}/Somministrazioni`, submission);
  }

  getAreas(areasIds: string[]): Observable<QuestionArea[]> {
    const filter: string = JSON.stringify(areasIds);
    return this.http
    .get<QuestionArea[]>(`${this.BASE_URL}/AreeDomanda?filter={"where":{"id":{"inq":${filter}}}}`);
  }

  getSubareas(subAreasIds: string[]): Observable<QuestionSubarea[]> {
    const filter: string = JSON.stringify(subAreasIds);
    return this.http
    .get<QuestionSubarea[]>(
      `${this.BASE_URL}/SottoareeDomanda?filter={"where":{"id":{"inq":${filter}}}}`
    );
  }

  getPreviousSubmissions(): Observable<GEVSubmission[]> {
    return this.http.get<GEVSubmission[]>(`${this.BASE_URL}/Somministrazioni?filter={"include":"cliente"}`);
  }

  getQuestionnaireWithRelatedQuestions(qId: string): Observable<Questionnaire> {
    return this.http
    .get<Questionnaire>(`${this.BASE_URL}/Questionari/${qId}?filter={"include":"domande"}`);
  }

  getAllQuestionnaires(): Observable<Questionnaire[]> {
    return this.http.get<Questionnaire[]>(`${this.BASE_URL}/Questionari`);
  }

  getQuestionnaire(id: string): Observable<Questionnaire> {
    return this.http.get<Questionnaire>(`${this.BASE_URL}/Questionari/${id}`);
  }

  getQuestionsForQuestionnaire(id: string): Observable<Question[]> {
    return this.http
    .get<Question[]>(
      `${this.BASE_URL}/Domande?filter={"questionarioId":"${id}"}`
    );
  }

  getAnswerTypes(): Observable<AnswerType[]> {
    return this.http.get<AnswerType[]>(`${this.BASE_URL}/TipoRisposte?access_token=${this.ACCESS_TOKEN}`);
  }

  getAnswersForQuestion(questionId: string): Observable<Answer[]> {
    return this.http.get<Answer[]>(`${this.BASE_URL}/Domande/${questionId}/risposte?access_token=${this.ACCESS_TOKEN}`);
  }

  patchCurrentSubmission(submission: GEVSubmission): Observable<GEVSubmission> {
    return this.http.patch<GEVSubmission>(`${this.BASE_URL}/Somministrazioni/${submission.id}`, submission);
  }

  setQuestionnaires(qsts: Questionnaire[]): void {
    this._questionnaires.next(qsts);
  }

}
