import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';

import { StructuresService } from '../../services/structure.service';
import { ProductsService } from '../../services/products.service';
import { NotificationsService } from '../../services/notifications.service';
import { CartService } from '../../services/cart.service';
import { Person, CartItem } from '../../interfaces/cart_item';
import { Order } from '../../interfaces/order';
// import { PricePipe } from '../../pipes/price.pipe';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { LanguageService } from '../../services/language.service';
import { Structure } from '../../interfaces/structure';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { Product } from '../../interfaces/product';
import { Minisite } from '../../interfaces/minisite';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
    @ViewChild(StripeCardComponent) card: StripeCardComponent;
    cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: '#666EE8',
                color: '#31325F',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                    color: '#CFD7E0',
                },
            },
        },
    };
    elementsOptions: StripeElementsOptions = {
        locale: 'it',
    };
    purchaseForm: FormGroup;

    totalPrice: number;
    productId: string;
    cartItems: CartItem[];

    structureObj: Structure;
    // labels: any;
    lang: string;
    logged: boolean;
    miniSite: Minisite;

    @Input() included: boolean | null;
    labels$: Observable<any>;

    constructor(
        private fb: FormBuilder,
        private stripeService: StripeService,
        private productsService: ProductsService,
        private structureService: StructuresService,
        private notificationService: NotificationsService,
        private cartService: CartService,
        private userService: UserService,
        private languageService: LanguageService,
        public dialog: MatDialog,
        private router: Router
    ) {}

    async ngOnInit() {
        this.isLogged();
        this.createForm();
        this.cartItems = <CartItem[]>this.cartService.getItems();
        this.labels$ =  this.languageService.retrieveLabels2();
        // this.lang = this.languageService.lang;
        this.languageService.currentLang.subscribe(lang => this.lang=lang);
        this.structureObj = this.structureService.retrieveStructure();
        this.structureService.getMinisiteInfos(this.structureObj.id).subscribe(
            (miniSite: Minisite[]) => {
              const myMinisite = miniSite.filter((m: Minisite) => {
                return m.strutturaId === this.structureObj.id;
              })[0];
              this.miniSite = myMinisite;
            },
            (error) => {
              this.notificationService.openSnackBar('Errore nel recuperare le info sito');
            }
          );
        // console.log(this.labels);
    }

    calcTotalPrice(): number {
        return this.cartService.calcTotalPrice();
    }

    addPerson(): FormControl {
        return new FormControl(null, Validators.required);
    }

    createForm() {
        this.purchaseForm = this.fb.group({
            name: ['', [Validators.required]],
        });
    }

    wasteCart() {
        this.cartService.wasteCart();
        this.cartItems = [];
    }

    async buy(): Promise<void> {
        /**
         * aprire il purchase page
         */

        const structureId = this.structureService.getId();
        this.router.navigate([`/${structureId}/purchase`]);
        return null;

    }

    createToken(): void {
        // const name = this.purchaseForm.get('name').value;
        const name = 'pippo';

        const cits = this.cartService.getItems();

        this.stripeService.createToken(this.card.element, { name }).subscribe((result) => {
            if (result.token) {
                // Use the token
                // console.log(result.token.id);
                const order: Order = {
                    carrello: cits,
                    stripeToken: result.token.id,
                    stripeHistory: {},
                    contractMode: 'online',
                    stato: 'nuovo',
                    timestamp: new Date(),
                    clienteId: '5ce26f3135fa98094282109e', // come lo recupero?
                    strutturaId: this.structureService.getId(),
                };
                this.productsService.purchaseProduct(order).subscribe((response: Order) => {
                    console.log('order is ', response);
                });
            } else if (result.error) {
                // Error creating the token
                console.log(result.error.message);
            }
        });
    }

    isLogged(): boolean {
        const u = this.userService.getCurrentUser();
        if (u) {
            this.logged = true;
            return true;
        }
        this.logged = false;
        return false;
    }

    logout() {
        this.logged = false;
    }

    openCartItem(cartItem: CartItem) {
        this.router.navigate([`/${this.structureService.getId()}/products/${cartItem.productId}`], {
            queryParams: { cartId: cartItem.id },
        });
        return null;
    }

    deleteCartItem(cartItem: CartItem) {
        // tslint:disable-next-line: no-use-before-declare
        const dialogRef = this.dialog.open(CartItemDialog, {
            width: '90%',
            data: { item: cartItem, labels: this.labels$, lang: this.lang },
        });
        dialogRef.afterClosed().subscribe((result: DialogData) => {
                // console.log('The dialog was closed', result);
                if (result) {
                    this.cartService.delItem(result.item);
                    this.cartItems = <CartItem[]>this.cartService.getItems();
                }
                // this.form.setValue({ username: result.username });
            });
    }
}

export interface DialogData {
    item: CartItem;
    labels: any;
    lang: string;
}

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'dialog-cart-item',
    templateUrl: 'dialog-cart-item.html',
    styleUrls: ['dialog-cart-item.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class CartItemDialog {
    constructor(
        public dialogRef: MatDialogRef<CartItemDialog>,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    regUser() {
        // const u = {} as User;
        // u.email = 'asd';
        // u.userName = 'asdasd';
        // u.password = '123';
        // this.userService.registerUser(u).toPromise();
        // // console.log('a');
    }

    close() {
        this.regUser();
        this.dialogRef.close();
    }
}
