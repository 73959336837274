import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapsService {
  localMap: Observable<boolean>

  constructor(private httpClient: HttpClient) { }

  public api(): Observable<boolean> {
    if (!this.localMap) {
      this.localMap = this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.GMAPKEY}`, 'callback')
        .pipe(
          map(() => true),
          catchError((e) => {
            console.log(e);
            return of(false);
          }),
        );
    }
    return this.localMap;

  }
}
