<section >
  <mat-card>
      <h3>
        {{labels['requestAssistance'] | translate: lang}}

        <img src="/assets/hotel_volver.png" alt="">
      </h3>

      <mat-divider></mat-divider>
    <form [formGroup]="requestForm" action="">
      <mat-form-field>
        <mat-select
        formControlName="requestType"
        (selectionChange)="selection($event)"
        placeholder="{{labels['requestType'] | translate: lang}} *">
          <mat-option *ngFor="let option of options" [value]="option.type">
            {{ option.label | translate: lang }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input
        formControlName="roomN"
        matInput placeholder="{{labels['roomNumber'] | translate: lang}} *">
      </mat-form-field>

      <mat-form-field class="full-width">
        <input
        formControlName="reservationName"
        matInput placeholder="{{labels['reservationName'] | translate: lang}} *">
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea
        formControlName="extraNotes"
        matInput
        placeholder="{{labels['extraNotes'] | translate: lang}}"></textarea>
      </mat-form-field>

      <button
      (click)="sendRequest()"
      [disabled]="!requestForm.valid"
      mat-raised-button
      color="primary"
      class="full-width">
      <span *ngIf="!loading">
        {{labels['request'] | translate: lang}}
      </span>

      <mat-icon
      *ngIf="loading"
      class="spin"
      >settings</mat-icon>
      </button>

    </form>
  </mat-card>
</section>

