<!-- <app-login-form *ngIf='!logged' [error]="''" (submitEM)='isLogged()'></app-login-form> -->
<app-breadcrumb *ngIf='isLogged()' (submitLogout)='logout()'></app-breadcrumb>
<div *ngIf="showSpinner" class="overlay">
    <div class="center">
        <mat-progress-spinner diameter=50 mode="indeterminate" color="accent">
        </mat-progress-spinner>
    </div>
</div>
<div *ngIf="showGreetings && (labels$ | async ) as labels" class="component-container">
    <div class="mat-elevation-z8 p-3">
        Grazie per l'acquisto.
    </div>
</div>

<div *ngIf="!showGreetings  && (labels$ | async ) as labels" class="component-container">
    <!-- <div *ngIf='logged' class="component-container"> -->
    <div class="mat-elevation-z8 p-3 mb-3" *ngIf='isLogged() == false'>
            <h4>Hai già un account StayMore?</h4>
            <!-- <p>Hai già fatto acquisti su StayMore?</p> -->
            <app-login-form included="true" (loginEvent)="loginResponseEvent($event)"></app-login-form>
            <!-- <button (click)="navigate('profile')" mat-raised-button color="warn">Accedi</button> -->
    </div>
    <div class="mat-elevation-z8 p-3">
        <span *ngIf='isLogged() == false'>
            <h4>Registrati e acquista</h4>
            <form id="contact" (ngSubmit)="submitCustomerForm()" [formGroup]="customerForm">
                <fieldset>
                    <input placeholder="Nome" type="text" tabindex="1" required autofocus formControlName='firstName'>
                </fieldset>
                <fieldset>
                    <input placeholder="Cognome" type="text" tabindex="2" formControlName='lastName'>
                </fieldset>
                <fieldset>
                    <input placeholder="Email" type="email" tabindex="3" required formControlName='email'>
                </fieldset>
                <fieldset>
                    <input placeholder="Telefono" type="tel" tabindex="4" formControlName='mobile'>
                </fieldset>
                <fieldset *ngIf="!logged">
                    <input placeholder="Password" type="password" tabindex="5" formControlName="password01">
                    <div *ngIf="f.password01.errors?.minlength">minlength required</div>
                </fieldset>
                <fieldset *ngIf="!logged">
                    <input placeholder="Ripeti la password" type="password" tabindex="6" formControlName="password02">
                </fieldset>
                <fieldset *ngIf="!logged && (f.password02.errors && !f.password01.errors)" class="invalid-feedback">
                    <div *ngIf="f.password02.errors.required">Confirm Password is required</div>
                    <div *ngIf="f.password02.errors.mustMatch">Passwords must match</div>
                </fieldset>
                <fieldset *ngIf="!logged && f.password01.errors" class="invalid-feedback">
                    <div *ngIf="f.password01.errors.required">Password is required</div>
                </fieldset>
                <p *ngIf="!logged">I dati saranno registrati per la creazione di un nuovo utente.</p>
            </form>
        </span>

        <app-cart included="true"></app-cart>
        <h4>{{labels['creditcard'] | translate: lang }}</h4>
        <div *ngIf="purchaseForm">
            <form novalidate (ngSubmit)="purchase()" [formGroup]="purchaseForm">
                <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"
                    (change)="onChange($event)"></ngx-stripe-card>
                <hr>
                <p>
                    {{labels['totalcharge'] | translate: lang }}: {{totPrice}} €
                </p>
                <hr>

                <section class="example-section">
                    <mat-checkbox formControlName="checked" name="checked">
                        {{labels['accepttec'] | translate: lang }}
                    </mat-checkbox>
                </section>

                <button type="submit" mat-raised-button color="warn"
                    [disabled]="!fp.checked.value || !(customerForm.valid || logged) || !validCC">
                    {{labels['confirm'] | translate: lang }}
                </button>
            </form>
        </div>

    </div>
</div>