import { Structure } from './../../interfaces/structure';
import { StructuresService } from './../../services/structure.service';
import { AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
import { Minisite } from '../../interfaces/minisite';
import { LanguageService } from '../../services/language.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { MapDirectionsService } from '@angular/google-maps/';
import { MapsService } from '../../../app/services/maps.service';

@Component({
  selector: 'app-how-to-arrive',
  templateUrl: './how-to-arrive.component.html',
  styleUrls: ['./how-to-arrive.component.scss']
})
export class HowToArriveComponent implements OnInit, AfterViewInit, OnDestroy {
  location: any;
  structure: Structure;
  lat: number;
  lng: number;
  miniSite: any;
  lang: string;
  apiLoaded: Observable<boolean>;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 8,
    center: { lat: -34, lng: 151 }
  }
  markerOptions: google.maps.MarkerOptions = { draggable: false };

  // readonly directionsResults$: Observable<google.maps.DirectionsResult|undefined>;
  directionsResults$: any;
  showDirectionsInMap: boolean;
  subs: Subscription;
  labels$: Observable<any>;

  constructor(
    private structureService: StructuresService,
    private languageService: LanguageService,
    private notificationsService: NotificationsService,
    private httpClient: HttpClient,
    private mapDirectionsService: MapDirectionsService,
    private mapService: MapsService
  ) { }

  ngOnDestroy(): void {
    if (this.subs) this.subs.unsubscribe();
    // this.apiLoaded.subscribe(false);
  }

  loadApi() {
    console.log('loadApi');

    this.apiLoaded = this.mapService.api();
    // this.subs = this.apiLoaded.subscribe();
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewChecked');
    if (!this.structure) {
      // this.structure = structure;
      this.structure = this.structureService.structure;

      // non c'è la struttura, quindi effettuo un next sul Subject del service

    } else {
      this.lat = this.structure.gps.lat;
      this.lng = this.structure.gps.lng;
      this.options.center = { lat: this.structure.gps.lat, lng: this.structure.gps.lng };
      if (!this.apiLoaded) this.loadApi();
      
    }
    // throw new Error('Method not implemented.');
  }

  private getMinisiteInfo() {
    this.structureService.getMinisiteInfos(this.structure.id).subscribe(
      (miniSite: Minisite[]) => {
        const myMinisite = miniSite.filter((m: Minisite) => {
          return m.strutturaId === this.structure.id;
        })[0];
        this.miniSite = myMinisite;
      },
      (error) => {
        this.notificationsService.openSnackBar('Errore nel recuperare le info sito');
      }
    );
  }

  ngOnInit() {
    this.showDirectionsInMap = false;
    this.languageService.currentLang.subscribe( lang => this.lang = lang );
    this.labels$ =  this.languageService.retrieveLabels2();

    this.notificationsService.setNewCurrentPage({ it: 'Come Arrivare', en: 'How to Arrive' });
    // this.structure = this.structureService.structure;
    if (this.structureService.structure) {
      this.structure = this.structureService.structure;
      this.getMinisiteInfo();
    }
    this.structureService.structureChanged$
      .subscribe((structure: Structure) => {
        this.lat = structure.gps.lat;
        this.lng = structure.gps.lng;
        this.structure = structure;
        this.options.center = { lat: this.structure.gps.lat, lng: this.structure.gps.lng };
        this.loadApi();
        this.getMinisiteInfo();
      });
  }

  public showDirection() {
    this.showDirectionsInMap = !this.showDirectionsInMap;
    this.loadApi();
    if (this.showDirectionsInMap && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.location = position.coords;
        console.log('position', position.coords);
        const request: google.maps.DirectionsRequest = {
          destination: this.structure.gps,
          origin: { lat: position.coords.latitude, lng: position.coords.longitude },
          travelMode: google.maps.TravelMode.DRIVING
        };
        this.directionsResults$ = this.mapDirectionsService.route(request).pipe(map(response => response.result));
      });
    } else {
      this.directionsResults$ = null;
    }
  }

}
