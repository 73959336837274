import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { MustMatch } from '../../modules/must-match.validator';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-landing-reset',
  templateUrl: './landing-reset.component.html',
  styleUrls: ['./landing-reset.component.scss']
})
export class LandingResetComponent implements OnInit, OnDestroy {
  submitted = false;
  resetPassForm = this.fb.group({
    password01: ['', Validators.required],
    password02: ['', Validators.required]
  }, {
    validator: MustMatch('password01', 'password02')
  });

  sub: any;
  challenge: any;
  mail: any;
  hash: any;
  homeLink: string;
  statusMessage: string;

  // portal/profile/q7MBd6ga-dravyUVa-5EfpU3RV-V4coRqU3/effeventu@yahoo.it/x
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;

    this.sub = this.route.params.subscribe(params => {
      this.challenge = params.challenge;
      this.mail = params.mail;
      this.hash = params.hash;
      // dispatch action to load the details here.
      /**
       * miglioramento: 
       * controllare prima di proseguire, se il token corrisponde alla mail
       */
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPassForm.controls; }

  onSubmit() {
    // console.log(this.challenge, this.mail, this.hash);
    // console.warn(this.resetPassForm.value);
    this.statusMessage = '';

    // stop here if form is invalid
    if (this.resetPassForm.invalid) {
      return;
    }

    // TODO: Use EventEmitter with form value
    /**
     * 
     * richiesta conferma pass reset con rotta
     * POST /mini-user/reset-execute  
     * payload:
     *   - email
     *   - challenge
     *   - pass
     * 
     * se reponse 406 challenge errato ... avviso 
     * se response 412 fallito causa precondizione errata
     * se response 201, ok ringraziamento e si passa nuovamente al sito struttura
     */
    this.userService.resetExec(this.mail, this.challenge, this.hash, this.resetPassForm.value.password01)
      .pipe(map((data) => {
        let sId = localStorage.getItem(window.btoa('structureId'));
        if (sId == null) sId = data.strutturaId;
        this.homeLink = `/${sId}?h=${this.hash}`;
        this.submitted = true;

      })).toPromise().catch(error => {
        // console.warn(error);
        this.submitted = false;
        switch (error.status) {
          case 406:
            console.warn("challenge errato ... avviso");
            this.statusMessage = 'Errore: challenge errato';
            break;
          case 412:
            console.warn("fallito causa precondizione errata");
            this.statusMessage = "Errore: challenge o email non corretti."
            break;
          default:
            console.warn("successo qualcosa di imprevisto");
            this.statusMessage = "Errore: successo qualcosa di imprevisto"
            break;
        }
      });
  }
}



