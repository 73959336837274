import { Component, OnInit, ViewChild } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { CategoriesService } from './services/categories.service';
// import { CategoryProduct } from './interfaces/category';
// import { NotificationsService } from './services/notifications.service';
// import { MatSidenav } from '@angular/material/sidenav';
import { Router, ActivatedRoute } from '@angular/router';
// import { StructuresService } from './services/structure.service';
// import { Structure } from './interfaces/structure';
import { LanguageService } from './services/language.service';
// import { MY_TOKEN } from './constants/strings';
import { environment } from '../environments/environment';
import { StructuresService } from './services/structure.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [LanguageService]
})
export class AppComponent implements OnInit {

    location: any;

    constructor(
        private languageService: LanguageService,
        private structureService: StructuresService,
        private route: ActivatedRoute,
        private titleService: Title
    ) {
        this.languageService.setLangFromBrowser();
        this.route.queryParamMap
            .subscribe(params => {
                const h= localStorage.getItem(environment.MY_TOKEN);
                if (params['params']['h'] ) {
                    localStorage.setItem(environment.MY_TOKEN, params['params']['h']);
                } else if ( h == null) {
                    localStorage.setItem(environment.MY_TOKEN, '-');
                }

            });
    }

    async ngOnInit(): Promise<void> {
        const structureId = await this.route.snapshot.paramMap.get('structureId');
        this.structureService.setId(structureId);
        this.titleService.setTitle(environment.TITLE)

    }

}
