import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CartService } from '../../services/cart.service';
import { UserService } from '../../services/user.service';
import { LanguageService } from '../../services/language.service';
import { OrdersService } from '../../services/orders.service';

import { CartItem } from '../../interfaces/cart_item';
import { Order } from '../../interfaces/order';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
    orderId: any;
    order: any;
    lang: string;
    logged: boolean;
    labels$: Observable<any>;

    constructor(
        private userService: UserService,
        private languageService: LanguageService,
        private ordersService: OrdersService,
        private cartService: CartService,
        public dialog: MatDialog,
        private route: ActivatedRoute
    ) { }


    getPrice(object: CartItem[]): number {
        // items = Object.assign(items)
        /**
         * gli items provengono dal template che a sua volta li ha presi
         * dalla chiamata http, quindi non possoggono il type e sono
         * obbligato a fre questo ciclo per eseguire su ciascuno un Object.assign che 
         * conferirà tutte le proprietà ed i metodi agli oggetti
         *
         * sarà il caso di farne una utility condivisa
         */
        const itemsToCalc: CartItem[] = [];
        for (const iterator of object) {
            const d = new CartItem();
            Object.assign(d, iterator);
            itemsToCalc.push(d);
        }
        return this.cartService.calcTotalPrice(itemsToCalc);
    }

    isLogged(): boolean {
        const u = this.userService.getCurrentUser();
        if (u) {
            return true;
        }
        return false;
    }

    logout() {
        this.logged = false;
        this.userService.logout().subscribe();
    }

    async ngOnInit() {
        this.logged = this.isLogged();
        // this.labels = await this.languageService.retrieveLabels2().toPromise();
        this.labels$ = this.languageService.retrieveLabels2();
        this.languageService.currentLang.subscribe(lang => this.lang = lang);
        // this.lang = this.languageService.lang;
        this.orderId = this.route.snapshot.paramMap.get('orderId');
        this.ordersService.getOrder(this.orderId).subscribe((o) => {
            const itemsToCalc: CartItem[] = [];
            for (const iterator of o.carrello) {
                const d = new CartItem();
                Object.assign(d, iterator);
                itemsToCalc.push(d);
            }
            o.carrello = itemsToCalc;
            this.order = o;
        });
    }

    openCartItem(order: Order) {

        const dialogRef = this.dialog.open(OrderItemDialog, {
            width: '90%',
            data: { item: order, labels$: this.labels$, lang: this.lang }
        });

    }
}


export interface DialogData {
    item: Order;
    labels$: Observable<any>;
    lang: string;
}

@Component({
    selector: 'dialog-order-item',
    templateUrl: 'dialog-order-item.html',
    styleUrls: ['./dialog-order-item.scss']
})
export class OrderItemDialog {
    constructor(
        public dialogRef: MatDialogRef<OrderItemDialog>,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { 
        console.log(data);
    }

    regUser() { }

    close() {
        this.regUser();
        this.dialogRef.close();
    }
}
