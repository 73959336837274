// import { MY_TOKEN } from './../constants/strings';
import { environment } from '../../environments/environment';

// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../interfaces/user';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = request.url.match(/openweather/);
        const myToken = localStorage[environment.MY_TOKEN];
        const u = this.getCurrentUser();
        // let a = {
        //     Hash: myToken
        // };
        // if (u) {
        //     a.Authorization: 'Bearer ' + u.token};
        //     myToken = u.token;
        // }
        if (myToken && !url) {
            if (!u) {
                request = request.clone({
                    setHeaders: {
                        Hash: myToken
                    }
                });
            } else {
                request = request.clone({
                    setHeaders: {
                        Hash: myToken,
                        Authorization: u.token
                    }
                });
            }
        }
        return next.handle(request);
    }

    private getCurrentUser(): User {
        const ua = localStorage.getItem(window.btoa('currentUser'));
        if (!ua) {
            return null;
        }
        const payload = window.atob(ua);
        return JSON.parse(payload);
    }
}
