<ng-container *ngIf="structure">
    <div *ngIf="apiLoaded | async">
        <google-map width="100%" [center]="options.center" [options]="options">
            <map-marker [position]="options.center" [options]="markerOptions"></map-marker>
            <map-directions-renderer *ngIf="(directionsResults$ | async) as directionsResults"
                [directions]="directionsResults"></map-directions-renderer>
        </google-map>
    </div>

    <div class="verbose" *ngIf="(labels$ | async) as labels">
        <div *ngIf='miniSite' [innerHtml]="miniSite.addressInfo | translate: lang"></div>
        <div>
            <a mat-raised-button class="fullWBtn" [href]="structure?.gmaps_url">{{ labels['directions'] | translate: lang}}</a>
        </div>
    </div>

</ng-container>