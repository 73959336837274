import { NotificationsService } from './../../services/notifications.service';
import { StructuresService } from './../../services/structure.service';
import { CategoriesService } from './../../services/categories.service';
import { CategoryProduct } from './../../interfaces/category';
import { LanguageService } from './../../services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProductsService } from '../../services/products.service';
import { Product } from '../../interfaces/product';
import { ComplexLabels } from '../../interfaces/complex_labels';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {
    lang: string;
    structureId: string;
    categoryId: string;
    products: Product[] = [];
    category: CategoryProduct;
    labels: ComplexLabels;
    subs: Subscription;

    constructor(
        private route: ActivatedRoute,
        private languageService: LanguageService,
        private productsService: ProductsService,
        private categoriesService: CategoriesService,
        private structureService: StructuresService,
        private notificationsService: NotificationsService,
        private router: Router
    ) {
        // this.structureId = this.structureService.getId();
        // this.lang = this.languageService.lang;
        // this.categoryId = this.route.snapshot.paramMap.get('categoryId');
        // this.labels = this.languageService.retrieveLabels();
    }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private async catChange(listGestoriStr) {
        this.productsService.getProductsFromCategoryId(this.structureId, this.categoryId, listGestoriStr).subscribe((resp: Product[]) => {
            this.products = resp;
            this.products = this.products.map((obj: Product) => {
                if (obj.image.indexOf("//") != -1) {
                    const items = obj.image.split('/');
                    obj.image = environment.BASE_URL + '/images/' + items[4];
                } else {
                    // console.log(obj.image);
                    obj.image = environment.BASE_URL + obj.image;
                }
                return obj;
            });
        });
        const cat = await this.categoriesService.getCategoryById(this.categoryId).toPromise();

        this.notificationsService.setNewCurrentPage(cat.name);
    }

    private async init() {

        this.structureId = this.structureService.getId();
        this.categoryId = this.route.snapshot.paramMap.get('categoryId');
        await this.recuperoLabel();
        // console.log(this.labels);
        // this.lang = this.languageService.lang;
        const listGestori = await this.structureService.getGestori().toPromise();
        const listGestoriStr = [];
        for (const iterator of listGestori) {
            listGestoriStr.push(iterator.id);
        }
        this.catChange(listGestoriStr);
        this.subs = this.router.events.subscribe((val) => {
            // see also 
            this.categoryId = this.route.snapshot.paramMap.get('categoryId');
            // console.log(val instanceof NavigationEnd);
            if (val instanceof NavigationEnd == true) {
                this.catChange(listGestoriStr);
            }
        });
    }

    async recuperoLabel() {
        this.labels = await this.languageService.retrieveLabels2().toPromise();
    }

    ngOnInit() {
        this.languageService.currentLang.subscribe(lang => this.lang=lang);
        this.init();

        // this.productsService.getProductsFromCategoryId(this.categoryId)
        //     .subscribe((response: Product[]) => {
        //         this.products = response;
        //     }, error => {
        //     });


        // this.categoriesService.getCategoryById(this.categoryId)
        //     .subscribe((response: CategoryProduct) => {
        //         this.category = response;
        //         this.notificationsService.setNewCurrentPage(this.category.name);
        //     }, error => {
        //     });
    }

    goToProduct(productId: string): void {
        this.router.navigate([`/${this.structureId}/products/${productId}`]);
    }

}
