import { ComplexLabels } from './../../interfaces/complex_labels';
import { PricePipe } from './../../pipes/price.pipe';
import { QuantityPipe } from '../../pipes/quantity.pipe';
import { PeriodPipe } from '../../pipes/period.pipe';
import { PriceRange } from './../../interfaces/price_range';
import { CategoryProduct } from './../../interfaces/category';
import { NotificationsService } from './../../services/notifications.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StructuresService } from '../../services/structure.service';
import { CategoriesService } from '../../services/categories.service';
import { ProductsService } from '../../services/products.service';
import { LanguageService } from '../../services/language.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, NgForm } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { Product } from '../../interfaces/product';
import { CartItem, Person } from '../../interfaces/cart_item';
import { Varianti } from '../../interfaces/varianti';
import { Attributo } from '../../interfaces/attributo';
import { CartService } from '../../services/cart.service';
import { Calendario } from '../../interfaces/calendario';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class ProductComponent implements OnInit, OnDestroy {
    // @ViewChild('cardInfo') cardInfo: ElementRef;

    labels: ComplexLabels;
    productId: string;
    lang: string;
    product: Product;
    categories: CategoryProduct[];
    purchaseForm: FormGroup;
    priceRanges: PriceRange[];

    totalPrice = 0; // da implementare

    error: string;
    variazioniPrezzo: Varianti[];
    validita: Calendario[];
    minDate: Date;
    cartItem: CartItem;
    priceForAge: Attributo;
    priceForQta: Attributo;
    priceForPeriod: Attributo;
    periodoValido: boolean;
    calendarioDispo: Calendario[];

    dateFilter = (d: Date | null): boolean => {
        const today = (new Date(d));
        if (this.calendarioDispo.length > 0) {
            for (const iterator of this.calendarioDispo) {
                const sDow = (new Date(d)).getDay();
                const checkDOW = iterator.dow == '' || iterator.dow.split(',').indexOf(sDow.toString()) > -1;
                const checkDfrom = iterator.date_from && today >= new Date(iterator.date_from);
                const checkDto = iterator.date_from && today <= new Date(iterator.date_to);

                if (checkDOW && checkDfrom && checkDto) {
                    return true;
                }
            }
        }
        return false;
    }
    showVariantiEta: boolean;
    showVariantiQta: boolean;
    showVariantiPeriodo: boolean;

    personOptions = new FormArray([]);

    constructor(
        private ar: ActivatedRoute,
        private languageService: LanguageService,
        private productsService: ProductsService,
        private categoriesService: CategoriesService,
        private structureService: StructuresService,
        private notificationService: NotificationsService,
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private router: Router,
        private cartService: CartService // private stripeService: StripeService
    ) {
        this.productId = this.ar.snapshot.paramMap.get('productId');
        this.minDate = new Date();
        this.cartItem = null;
    }

    navigateToCategory(category: CategoryProduct): void {
        const structureId = this.structureService.getId();
        this.router.navigate([`/${structureId}/catalogue/${category.id}`]);
    }

    addPerson(): FormControl {
        return new FormControl(null);
    }

    addPeople(): FormGroup {
        return new FormGroup({
            person: this.addPerson(),
            options: this.personOptions
        });
    }

    addOption() {
        this.personOptions.push(new FormControl(''));
    }

    getOptions(item) {
        return item.controls['options'];
    }

    getPeople() {
        return this.purchaseForm.controls['people'];
    }

    async createForm() {
        const oggi = new Date();
        if (this.priceRanges.length > 0) {
            this.calendarioDispo = await this.productsService.getCalendarioList(this.priceRanges[0].id, oggi.toISOString().split('T')[0]).toPromise();
        }
        /**
         * preparo la personOptions array
         */
        this.purchaseForm = this.fb.group({
            reservationDate: [null, Validators.required],
            qty: [null, Validators.required],
            people: this.fb.array([this.addPeople()]),
            // people: this.fb.array([this.addPerson()]),
        });

        if (this.cartItem) {
            this.purchaseForm.controls['reservationDate'].setValue(this.cartItem.date_in);
            this.purchaseForm.controls['qty'].setValue(+this.cartItem.people.length);

            const event: Object = {
                value: +this.cartItem.people.length,
            };
            this.setAgeFields(event);
            let i = 0;
            for (const iterator of this.purchaseForm.controls['people']['controls']) {
                iterator.setValue(this.cartItem.people[i++].age);
            }
            this.setAgeFields(event);

            // this.purchaseForm.controls['people'].setValue(this.cartItem.people);
        }

        this.purchaseFormValueSubscribe();
    }

    get f() { return this.purchaseForm.controls; }


    /**
     * gestisce la quantità dinamica di righe per raccogliere l'età
     * @param event 
     */
    setAgeFields(event): void {
        // if (!this.showVariantiEta) return null;
        const times = (n, f) => {
            while (n-- > 0) {
                f();
            }
        };
        const formArray: FormArray = this.purchaseForm.controls.people as FormArray;
        const diff: number = Math.abs(formArray.length - event.value);
        if (event.value > formArray.controls.length) {
            times(diff, () => {
                formArray.push(this.addPeople());
            });
        } else if (event.value < formArray.controls.length) {
            times(diff, () => {
                formArray.removeAt(formArray.length - 1);
            });
        }

    }


    purchaseFormValueSubscribe() {
        this.purchaseForm.valueChanges.subscribe(async (formValue) => {
            // console.log('subscribeD');
            this.validita = await this.productsService.getCalendario(this.priceRanges[0].id, formValue.reservationDate.format('YYYY-MM-DD')).toPromise();

            this.totalPrice = 0;
            for (let age of formValue.people) {
                if (age && typeof age === 'object') {
                    age = age.person;
                }
                const detrarre = this.calcPrezzo(age, formValue);
                this.totalPrice += this.product.special_price + detrarre;
            }
        });
    }

    private calcPrezzo(age, formValue): number {
        const pricePipe = new PricePipe();
        const quantityPipe = new QuantityPipe();
        const periodPipe = new PeriodPipe();
        const peopleFormArray = this.purchaseForm.controls.people as FormArray;

        this.priceForAge = {} as Attributo;
        this.priceForQta = {} as Attributo;

        if (this.variazioniPrezzo.length > 0) {
            /** qui va colto il gruppo fascia di età per calcolare lo sconto prezzo */
            const variantiEta = this.variazioniPrezzo.filter(x => ((x.gruppo) && (x.gruppo.id == "5f8da0b6f787651684ca315c")));
            const variantiQta = this.variazioniPrezzo.filter(x => ((x.gruppo) && (x.gruppo.id == "5f8da0bef787651684ca315d")));
            const variantiPeriodo = this.variazioniPrezzo.filter(x => ((x.gruppo) && (x.gruppo.id == "5f8da0fcf787651684ca315e")));
            const variantiCustom = this.variazioniPrezzo.filter(x => ((x.gruppo) && (x.gruppo.id == "615480df70367e3d4b9aeb6c")));
            this.showVariantiEta = (variantiEta.length > 0 ? true : false);
            this.showVariantiQta = (variantiQta.length > 0 ? true : false);
            this.showVariantiPeriodo = (variantiPeriodo.length > 0 ? true : false);

            this.priceForAge = pricePipe.transform(age, variantiEta ?? [], 0);
            this.priceForQta = quantityPipe.transform(formValue.people.length, variantiQta ?? [], 0);
            this.priceForPeriod = periodPipe.transform(formValue.reservationDate, variantiPeriodo ?? [], 0);
            // console.log(this.priceForAge);
            // console.log(this.priceForQta);
            // console.log(this.priceForPeriod);
            // console.log (peopleFormArray.controls.length);
            for (const iterator of peopleFormArray.controls) {
                if (this.showVariantiEta) {
                    iterator.setValidators([Validators.required]);
                } else {
                    iterator.clearValidators();
                }
            }
        }

        /**
         * se non ci sono validità il prodotto non è vendibile
         * se ci sono devo controllare il DOW
         */
        this.periodoValido = false;
        if (this.validita.length > 0) {
            for (const iterator of this.validita) {
                // const sDow = new Date(formValue.reservationDate).getDay();
                if (this.dateFilter(new Date(formValue.reservationDate))) {
                    this.periodoValido = true;
                }
                // if (iterator.dow.split(',').indexOf(sDow.toString()) > -1) {
                //     this.periodoValido = true;
                // }
            }
        }

        let detrarre = 0;
        if (this.priceForAge) {
            if (this.priceForAge.sconto_tipo == 'F') {
                detrarre += this.priceForAge.sconto;
            } else if (this.priceForAge.sconto_tipo == 'P') {
                detrarre += (this.product.special_price * this.priceForAge.sconto) / 100;
            }
        }
        if (this.priceForQta) {
            if (this.priceForQta.sconto_tipo == 'F') {
                detrarre += this.priceForQta.sconto;
            } else if (this.priceForAge.sconto_tipo == 'P') {
                detrarre += (this.product.special_price * this.priceForQta.sconto) / 100;
            }
        }
        if (this.priceForPeriod) {
            if (this.priceForPeriod.sconto_tipo == 'F') {
                detrarre += this.priceForPeriod.sconto;
            } else if (this.priceForPeriod.sconto_tipo == 'P') {
                detrarre += (this.product.special_price * this.priceForPeriod.sconto) / 100;
            }
        }

        for (const people of peopleFormArray.controls) {
            const z = this.getOptions(people).controls;
            for (let index = 0; index < z.length; index++) {
                const element = z[index];
                // console.log(element.value, this.getVariazioniPrezzoCustom()[index].sconto)
                if (element.value === true) {
                    detrarre += this.getVariazioniPrezzoCustom()[index].sconto;
                }
            }

        }
        return detrarre;
    }

    public getVariazioniPrezzoCustom(): Attributo[] {
        const vp = this.variazioniPrezzo.filter(x => ((x.gruppo) && (x.gruppo.id == "615480df70367e3d4b9aeb6c")));
        return vp.length > 0 ? vp[0].attributi : [];
    }

    async addToCart(event: Event): Promise<void> {
        const cart: CartItem = {} as CartItem;
        cart.people = [];
        cart.productId = this.productId;
        cart.structureId = this.structureService.getId();
        cart.prodotto = this.product;
        if (typeof this.purchaseForm.value.reservationDate === 'string') {
            cart.date_in = this.purchaseForm.value.reservationDate;
        } else {
            cart.date_in = this.purchaseForm.value.reservationDate.format('YYYY-MM-DD');
        }
        cart.totalPrice = this.totalPrice;
        // console.log(this.purchaseForm.value.reservationDate.toDate());
        for (let age of this.purchaseForm.value.people) {
            if (age && typeof age === 'object') {
                age = age.person;
            }
            const detrarre = this.calcPrezzo(age, this.purchaseForm.value);
            const personObj: Person = {
                age: age,
                associatedPrice: this.product.special_price + detrarre,
            };
            cart.people.push(personObj);
        }

        this.cartItem = null; // in attesa di creare la whislist
        this.cartService.wasteCart();
        if (this.cartItem) {
            cart.id = this.cartItem.id;
            this.cartService.updateItem(cart);
            this.notificationService.openSnackBar('Carrello aggiornato');
        } else {
            this.cartService.addItem(cart);
            this.notificationService.openSnackBar('Vado al carrello');
        }
        setTimeout(() => {
            this.router.navigate([`/${this.structureService.getId()}/cart`]);
        }, 500);
    }

    ngOnDestroy() {
        // if (this.cardInfo) {
        //     this.card.removeEventListener('change', this.cardHandler);
        //     this.card.destroy();
        // }
    }

    onChange({ error }) {
        if (error) {
            this.error = error.message;
        } else {
            this.error = null;
        }
        this.cd.detectChanges();
    }

    async ngOnInit() {
        this.labels = await this.languageService.retrieveLabels2().toPromise();
        // this.lang = this.languageService.lang;
        this.languageService.currentLang.subscribe(lang => this.lang=lang);

        this.periodoValido = true;

        try {
            this.product = await this.productsService.getProductById(this.productId).toPromise();
            if (this.product.image.indexOf("//") != -1) {
                const items = this.product.image.split('/');
                this.product.image = environment.BASE_URL + '/images/' + items[4];
            } else {
                this.product.image = environment.BASE_URL + this.product.image;
            }

        } catch (error) {
            this.notificationService.openSnackBar('Errore nel caricamento del prodotto');
        }

        try {
            this.categories = await this.categoriesService
                .getCategoriesByProductId(this.product.id)
                .toPromise();
        } catch (error) {
            this.notificationService.openSnackBar(
                'Errore nel caricamento delle categorie prodotto'
            );
        }

        this.priceRanges = await this.productsService
            .getPriceRangesForProduct(this.productId, this.structureService.getId())
            .toPromise();

        this.variazioniPrezzo = [];
        if (this.priceRanges.length > 0) {
            this.variazioniPrezzo = await this.productsService
                .getVariazioni(this.priceRanges[0].id)
                .toPromise();
            for (const iterator of this.getVariazioniPrezzoCustom()) {
                // for (const iter of iterator.attributi) {
                this.addOption();
                // }
            }
        }
        // for (const variante of this.variazioniPrezzo) {
        //     if (variante.gruppo) {
        //         // console.log(variante.gruppo.name);
        //         for (const attributo of variante.attributi) {
        //             // console.log(attributo);
        //         }
        //     }
        //     // console.log(variante.gruppo.name);
        // }

        /** min_sell ... max_sell */
        this.product.availabilities = [];
        let i = 0;
        for (i = this.product.min_sell; i <= this.product.max_sell; i++) {
            this.product.availabilities.push(i);
        }

        /**
         * Verifico se stiamo editando un CartItem
         */
        this.ar.queryParams.subscribe((x: Object) => {
            if ('cartId' in x) {
                this.cartItem = this.cartService.getItem(+x['cartId']);
            }
        });

        this.createForm();

    }
}
