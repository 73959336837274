import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WeatherComponent } from './../modules/weather/weather.component';
import { ExtraDetailComponent } from '../components/extra-detail/extra-detail.component';
import { RouterModule, Routes } from '@angular/router';
// import { AppComponent } from '../app.component';
import { NgModule } from '@angular/core';
import { HomeComponent } from '../components/home/home.component';
import { MainComponent } from '../components/main/main.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { CatalogueComponent } from '../components/catalogue/catalogue.component';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule, MatDialogClose } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';

import { NgxStripeModule } from 'ngx-stripe';

import { AuthorizeGuard as AuthGuard } from '../authorize.guard';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CategoryPipe } from '../pipes/category.pipe';
import { CategoryComponent } from '../components/category/category.component';
import { TranslationPipe } from '../pipes/translation.pipe';
import { ProductComponent } from '../components/product/product.component';
import { PricePipe } from '../pipes/price.pipe';
import { ChatComponent } from '../modules/chat/chat.component';
import { TranslationModule } from '../modules/translation.module';
import { GuestsServicesComponent } from '../modules/guests-services/guests-services.component';
import { HowToArriveComponent } from '../modules/how-to-arrive/how-to-arrive.component';
import { BrowserModule } from '@angular/platform-browser';
import { OrdersComponent } from '../components/orders/orders.component';
import { LoginFormComponent } from '../components/login-form/login-form.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { OrderDetailComponent } from '../components/order-detail/order-detail.component';
import { CartComponent } from '../components/cart/cart.component';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { PurchaseComponent } from '../components/purchase/purchase.component';
import { LandingResetComponent } from '../components/landing-reset/landing-reset.component';
import { ContactComponent } from '../components/contact/contact.component';
import { environment } from '../../environments/environment';

export const appRoutes: Routes = [
    {
        path: ':structureId', component: MainComponent, children: [
            { path: '', component: HomeComponent, data: { state: 'home' } },
            { path: 'chat', component: ChatComponent, data: { state: 'chat' } },
            { path: 'guests', component: GuestsServicesComponent, data: { state: 'guests' } },
            { path: 'how-to-arrive', component: HowToArriveComponent, data: { state: 'how' } },
            { path: 'catalogue', component: CatalogueComponent, data: { state: 'catalogue' } },
            { path: 'catalogue/:categoryId', component: CategoryComponent, data: { state: 'category' } },
            { path: 'products/:productId', component: ProductComponent, data: { state: 'prod' } },
            { path: 'extra/:extraId', component: ExtraDetailComponent, data: { state: 'extra' } },
            { path: 'weather', component: WeatherComponent, data: { state: 'weather' } },
            { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], data: { state: 'order' } },
            { path: 'profile', component: ProfileComponent, data: { state: 'profile' } },
            { path: 'order-detail/:orderId', component: OrderDetailComponent, canActivate: [AuthGuard], data: {} },
            { path: 'cart', component: CartComponent, data: { state: 'cart' } },
            { path: 'purchase', component: PurchaseComponent, data: { state: 'purchase' } },
            { path: 'contact', component: ContactComponent, data: { state: 'contact' } }
        ]
    }, {
        path: 'portal/profile/:challenge/:mail/:hash', component: LandingResetComponent, data: {}
    }
];

@NgModule({
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    ],
    declarations: [
        MainComponent,
        HomeComponent,
        CatalogueComponent,
        ExtraDetailComponent,
        CategoryPipe,
        CategoryComponent,
        ProductComponent,
        OrdersComponent,
        LoginFormComponent,
        PricePipe,
        ProfileComponent,
        CartComponent,
        ContactComponent,
        OrderDetailComponent,
        BreadcrumbComponent,
        PurchaseComponent
    ],
    imports: [
        NgxStripeModule.forRoot(environment.STRIPE_TOKEN),
        TranslationModule,
        CommonModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatTableModule, MatSortModule, MatPaginatorModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatDatepickerModule,
        FormsModule,
        MatSnackBarModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatGridListModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatRadioModule,
        MatBadgeModule,
        MatMenuModule
    ],
    exports: [
        CategoryComponent,
        ProductComponent,
        MainComponent,
        HomeComponent,
        CatalogueComponent,
        OrdersComponent,
        LoginFormComponent,
        ExtraDetailComponent,
        BrowserAnimationsModule,
        MatFormFieldModule, MatDialogClose,
        BreadcrumbComponent
    ]
})
export class RoutingModule { }
