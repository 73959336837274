<h3 mat-dialog-title>Dettagli servizio</h3>
<div mat-dialog-content>
    <div>
        <img [src]="data.item.prodotto.image" alt="" width="100%">
    </div>
    <div>
        <h4>
            {{data.item.prodotto.name[data.lang]}}
        </h4>
        <small>
            {{data.item.prodotto.short_description[data.lang]}}
        </small>
    </div>
    <div>
        <hr>
        <p> Data di prenotazione:<br> {{data.item.date_in}}</p>
        <p> Numero persone: <br>es. 3</p>
        <hr>
        <p>Prezzo totale: {{data.item.totalPrice}}</p>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)='close()'>Chiudi</button>
    <button mat-raised-button color="warn" [mat-dialog-close]="data" type="button" cdkFocusInitial>Rimuovi</button>
</div>