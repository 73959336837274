// import { DOMAIN } from './../constants/strings';
import { environment } from '../../environments/environment';
import { Answer } from './../interfaces/answer';
import { Question } from './../interfaces/question';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AnswersType } from '../interfaces/answers_type';
import { ServiceData } from '../interfaces/service_data';

@Injectable()
export class ChatService {

    constructor(
        private http: HttpClient
    ) { }

    fetchQuestionnaire(): Observable<Question[]> {
        return this.http
            .get<Question[]>(`${environment.DOMAIN}/Domande?filter={"questionarioId": "5afbe8358151342dde3977eb"}`);
    }

    getAnswersForQuestion(questionId: string): Observable<Answer[]> {
        return this.http
            .get<Answer[]>(`${environment.DOMAIN}/Domande/${questionId}/risposte`);
    }

    getAnswersTypeName(answersTypeId: string): Observable<AnswersType> {
        return this.http.get<AnswersType>(`${environment.DOMAIN}/TipoRisposte/${answersTypeId}`);
    }

    getOptionsForSelect(domain: string): Observable<ServiceData[]> {
        return this.http.get<ServiceData[]>(`${environment.DOMAIN}/enums?filter={"area": "${environment.DOMAIN}"}`);
    }

}
