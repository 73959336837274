import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Order } from '../interfaces/order';
import { User } from '../interfaces/user';
import { UserService } from './user.service';
import { resolve } from 'url';

@Injectable({
    providedIn: 'root'
})
export class OrdersService {

    constructor(private http: HttpClient, private userService: UserService) { }

    getOrders(): Observable<Order[]> {
        const u: User = this.userService.getCurrentUser();
        if (!u) {
            return of();
        }

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Hash': u.token
            })
        };
        const url = `${environment.DOMAIN}/Ordini`;
        return this.http.get<Order[]>(url + `?filter={"include":"struttura","order": "timestamp desc"}&access_token=${u.token}`, options);
    }

    getOrder(id: string): Observable<Order> {
        const u: User = this.userService.getCurrentUser();
        if (!u) {
            return of();
        }
        const url = `${environment.DOMAIN}/Ordini/${id}?filter={"include":"struttura"}&access_token=${u.token}`;
        return this.http.get<Order>(url);
    }

}
