import { LanguageService } from './../services/language.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationPipe } from '../pipes/translation.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TranslationPipe
  ],
  providers: [LanguageService],
  exports: [
    TranslationPipe
  ]
})
export class TranslationModule { }
