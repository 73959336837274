const apiBase = 'http://gev.greenconsulting.it';

export const environment = {
    GMAPKEY: 'AIzaSyBqkZFUUTeEbbBl0K7JWg32SKPCC7a-NIg',
    production: true,
    BASE_URL: `${apiBase}`,
    DOMAIN: `${apiBase}/api`,
    MY_TOKEN: 'myToken',
    CLIENT_ID_KEYWORD: 'clientId',
    SERVER_API_PATH: `${apiBase}/api`,
    SERVER_USER_PATH: `${apiBase}/mini_user`,
    SERVER_LOGIN_PATH: `${apiBase}/login`,
    SERVER_LOGOUT_PATH: `${apiBase}/logoutfe`,
    STRIPE_TOKEN: 'pk_test_jeGx4I8NLsLjVqbjoSOBC2qF',
    TITLE: 'StayMore -stage-'
};
