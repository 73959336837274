export enum QuestionTypes {
  radio = 'radio',
  checkbox = 'checkbox',
  radio_list = 'radio_list',
  select_list = 'select_list',
  date = 'date',
  text = 'text',
  multi_check = 'multi_check',
  multi_smile = 'multi_smile'
}
