import { ComplexLabels } from './../interfaces/complex_labels';
// import { DOMAIN } from './../constants/strings';
import { environment } from '../../environments/environment';
import { CategoryProduct } from './../interfaces/category';
import { Label } from './../interfaces/label';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { TranslationEntry } from '../interfaces/translation_entry';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LanguageService {

    private langSource = new BehaviorSubject('en');
    currentLang = this.langSource.asObservable();

    lang: string;
    labels: ComplexLabels;
    private _labels = new Subject<ComplexLabels>();
    public labels$ = this._labels.asObservable();

    constructor(private http: HttpClient) { }

    changeMessage(message: string) {
        this.langSource.next(message)
    }

    setLang(lang: string): void {
        this.lang = lang;
        this.changeMessage(lang);
    }

    setLangFromBrowser(): void {
        console.log('SETLANGFROMBROWSER', navigator.language.substr(0, 2));
        this.lang = navigator.language.substr(0, 2) || 'en';
        this.changeMessage(this.lang);
        console.log('SETLANGFROMBROWSER', this.lang);
    }

    populateLabels(): void {
        const objToSave: ComplexLabels = {};
        this.getLabelsFromRemote()
            .subscribe((response: Label[]) => {
                response.map((label: Label) => {
                    objToSave[label.key] = label.label;
                });
                this.labels = objToSave;
                this._labels.next(objToSave);
            });
    }

    getLabelsFromRemote(): Observable<Label[]> {
        return this.http.get<Label[]>(`${environment.DOMAIN}/Etichette`);
    }

    retrieveLabels2(): Observable<any> {
        return this.http.get<Label[]>(`${environment.DOMAIN}/Etichette`)
            .pipe(
                map(entity => {
                    const objToSave: ComplexLabels = {};
                    entity.map((label: Label) => {
                        objToSave[label.key] = label.label;
                    });
                    return objToSave;
                }),
                catchError(err => {
                    console.log('Handling error locally and rethrowing it...', err);
                    // this.spinner.hide();
                    return err;
                })
            );
    }
    retrieveLabels(): ComplexLabels {
        // console.log('FIRED! retrieving labels', this.labels);
        if (!this.labels) {
            // console.log('caso1');
            this.getLabelsFromRemote()
                .subscribe((response: Label[]) => {
                    const objToSave: ComplexLabels = {};
                    response.map((label: Label) => {
                        objToSave[label.key] = label.label;
                    });
                    this.labels = objToSave;
                    this._labels.next(this.labels);
                    return this.labels;
                });
        } else {
            return this.labels;
        }
        return this.labels;
    }


}
