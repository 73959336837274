<!-- <app-breadcrumb (submitLogout)='logout()'></app-breadcrumb> -->
<!-- <app-breadcrumb *ngIf='logged' [error]="''" (submitLogout)='logout()'></app-breadcrumb>
 -->
<div class="component-container">
    <div class="mat-elevation-z9 p-3"  *ngIf="(labels$ | async ) as labels">
        <mat-card class="example-card">
            <mat-card-header>
                <div mat-card-avatar [style.background-image]="'url(' + miniSite?.image + ')'"></div>
                <!-- <div mat-card-avatar ></div> facilityname-->
                <mat-card-title>{{labels['facilityname'] | translate: lang }}: {{structureObj?.businessName}}</mat-card-title>
                <mat-card-subtitle>{{labels['city'] | translate: lang }}: {{structureObj?.city}} - {{structureObj?.zip}}</mat-card-subtitle>
                <mat-card-subtitle>{{labels['contacts'] | translate: lang }}: {{structureObj?.businessPhoneNumber}} {{structureObj?.representativeEmail}}
                </mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
                <h2><strong>{{labels['cartvalue'] | translate: lang }}: {{calcTotalPrice()}} €</strong></h2>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="component-container">
    <div class="mat-elevation-z9 p-3" *ngIf="(labels$ | async ) as labels">

        <div *ngIf="cartItems.length == 0 ">
            <p>{{labels['cartisempty'] | translate: lang }}</p>
        </div>
        <div *ngIf="cartItems.length > 0">
            <table class="p-3">
                <tbody>
                    <tr *ngFor="let item of cartItems">
                        <td style="border:1px solid #ccc">
                            <div class="content-box" *ngIf="!included">
                                <img [src]="item.prodotto.image" alt="" class="image_service">

                            </div>
                            <div class="descrizione-servizio">
                                <p class="nome-servizio">{{item.prodotto.name | translate:lang}}</p>
                                <p class="breve-servizio">{{item.prodotto.short_description | translate:lang}}</p>
                                <p class="numero-persone"> Numero persone: {{item.people.length}}</p>
                                <p class="costo-servizio">Costo servizio: {{item.calcItemPrice()}} €</p>
                            </div>
                            <div class="bottone-modifica" *ngIf="!included">
                                <button mat-icon-button aria-label="Open" (click)="openCartItem(item)">
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button mat-icon-button aria-label="Open" class="elimina"
                                    (click)="deleteCartItem(item)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>

        </div>
        <div class="procedere" *ngIf="!included">
            <button mat-raised-button color="primary" (click)='buy()' [disabled]="cartItems.length == 0">{{labels['buy'] | translate: lang }}</button>
            <button mat-raised-button color="warn" (click)="wasteCart()" [disabled]="cartItems.length == 0">{{labels['empty'] | translate: lang }}</button>
        </div>

    </div>
</div>