import { TranslationEntry } from './../interfaces/translation_entry';
import { CategoryProduct } from './../interfaces/category';
import { Pipe, PipeTransform } from '@angular/core';
/**
 * Rappresenta il `pipe` automatico per gestire {@link TranslationEntry}, basta passare
 * un parametro di `languageCode`
 * @constructor
 */
@Pipe({name: 'translate'})
export class TranslationPipe implements PipeTransform {


  transform(translationEntry: TranslationEntry, myLang: string): string {
    if (!translationEntry) {
      return '';
    } else {
      if ((!translationEntry[myLang]) || (translationEntry[myLang] === '')) {
        return translationEntry.en;
      } else {
        return translationEntry[myLang];
      }
    }

  }
}
