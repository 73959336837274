import { Pipe, PipeTransform } from '@angular/core';
import { Attributo } from '../interfaces/attributo';
import { Varianti } from '../interfaces/varianti';

@Pipe({
  name: 'period'
})
export class PeriodPipe implements PipeTransform {

  transform(period: unknown, fasce: Varianti[], ...args: unknown[]): Attributo {
    return fasce.length > 0 && fasce[0].attributi.filter((item: Attributo) => {
      return ((period >= new Date(item.data_da).setHours(0,0,0,0)) && (period <= new Date(item.data_a).setHours(0,0,0,0)));
    })[0];
  }

}
