// import { DOMAIN } from './../constants/strings';
import { environment } from '../../environments/environment';
import { CategoryProduct } from './../interfaces/category';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StructuresService } from './structure.service';

@Injectable()
export class CategoriesService {
    private excludedId: string[];
    constructor(private http: HttpClient, private structureService: StructuresService) {}

    setExcludedId(exId: string[]) {
        this.excludedId = exId;
    }
    getExcludedId() {
        return this.excludedId;
    }

    getCategories(id: string, catfilter: string[]): Observable<CategoryProduct[]> {
        const devUrl = `${environment.DOMAIN}/CategorieProdotto`;
        // const prodUrl = `${environment.DOMAIN}/Strutture/${id}/categorie`;
        let filterStr = '';
        if (!catfilter) {
            catfilter = this.excludedId;
        }
        filterStr = `?filter={ "order": "appearance_order ASC", "where": {"id": {"nin": ["${catfilter.join('","')}"]}}, "include": [ { "relation": "categoriaprodottoStrutture", "scope": { "where": { "strutturaId": "${this.structureService.getId()}"} } }, {"relation": "prodotti", "scope": { "where": { "strutturaId": "${this.structureService.getId()}"} } } ] }`;
        return this.http.get<CategoryProduct[]>(`${devUrl}${filterStr}`);
    }

    getCategoryById(id: string): Observable<CategoryProduct> {
        return this.http.get<CategoryProduct>(`${environment.DOMAIN}/CategorieProdotto/${id}`);
    }

    getCategoriesByProductId(productId: string): Observable<CategoryProduct[]> {
        return this.http.get<CategoryProduct[]>(
            `${environment.DOMAIN}/Prodotti/${productId}/categorieprodotto`
        );
    }
}
