<app-login-form *ngIf='!logged() && !showActivation' [error]="''" (submitEM)='log($event)'></app-login-form>
<app-breadcrumb *ngIf='logged()' [error]="''" (submitLogout)='log($event)'></app-breadcrumb>

<div *ngIf="showActivation && ( labels$ | async ) as labels" class="profile-container p-3">
    {{activation | async}}
    <p *ngIf="true">
        Utente verificato. Procedi ora a impostare la tua password.
    </p>
    <h4>Imposta password</h4>
    <form id="pwd-set" [formGroup]="activateForm">
        <mat-form-field>
            <input matInput placeholder="Nuova password" type="password" name="password" formControlName="password" required>
            <mat-error *ngIf="f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Ripeti password" type="password" name="confirmPassword" formControlName="confirmPassword" required>
            <mat-error *ngIf="f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
            </mat-error>
        </mat-form-field>
    </form>
    <button mat-raised-button>
        <mat-icon>save</mat-icon>Salva
    </button>

</div>

<div class="profile-container" *ngIf='logged() && ( labels$ | async ) as labels'>
    <div class="mat-elevation-z8 p-3">
        <div class=" button-container">
            <button mat-button color="primary" (click)='openDialog()' aria-label="salva cliente">
                <mat-icon>security</mat-icon> {{ labels['passwordchange'] | translate: lang}}
            </button>
        </div>
    </div>
    <div class="mat-elevation-z8 p-3">
        <form class="profile-form">
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>{{ labels['namelabel'] | translate: lang}}</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" name="nome" [(ngModel)]='currentCliente.firstName'>
            </mat-form-field>
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>{{ labels['surname'] | translate: lang}}</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" name="cognome" [(ngModel)]='currentCliente.lastName'>
            </mat-form-field>
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>Email</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" name="email" [(ngModel)]='currentCliente.email'>
            </mat-form-field>
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>{{ labels['birthdate'] | translate: lang}}</mat-label>
                <input matInput [matDatepicker]="picker" name="birthDate" [(ngModel)]='currentCliente.birthDate'>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>{{ labels['birthplace'] | translate: lang}}</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" name="birthPlace" [(ngModel)]='currentCliente.birthPlace'>
            </mat-form-field>
            <!-- <mat-form-field appearance="fill" class='profile-form-field-full-width'> -->
            <label>{{ labels['sex'] | translate: lang}} </label>
            <mat-radio-group aria-label="Select an option" [(ngModel)]='currentCliente.sex' name="sex">
                <mat-radio-button value="M">{{ labels['male'] | translate: lang}}</mat-radio-button>
                <mat-radio-button value="F">{{ labels['female'] | translate: lang}}</mat-radio-button>
                <mat-radio-button value="">non specificato</mat-radio-button>
            </mat-radio-group>
            <!-- <input matInput (keyup)="applyFilter($event.target.value)" name="sex" > -->
            <!-- </mat-form-field> -->
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>mobile</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" name="mobile" [(ngModel)]='currentCliente.mobile'>
            </mat-form-field>
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>{{ labels['address'] | translate: lang}}</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" name="address" [(ngModel)]='currentCliente.address'>
            </mat-form-field>
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>{{ labels['city'] | translate: lang}}</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" name="city" [(ngModel)]='currentCliente.city'>
            </mat-form-field>
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>{{ labels['country'] | translate: lang}}</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" name="country" [(ngModel)]='currentCliente.country'>
            </mat-form-field>
            <mat-form-field appearance="fill" class='profile-form-field-full-width'>
                <mat-label>{{ labels['country'] | translate: lang}}</mat-label>
                <!-- <input matInput (keyup)="applyFilter($event.target.value)" name="country" [(ngModel)]='currentCliente.country'> -->
                <mat-select [(ngModel)]="lang" name="lang-selector">
                    <mat-option *ngFor="let lng of LANGS" [value]="lng.iso" (click)="setLang(lng.iso)">
                      <img [src]='lng.img' style="width: 20px; ; padding-right: 12px;"> {{lng.description}}
                    </mat-option>
                  </mat-select>
            </mat-form-field>
            <div class="button-container">
                <button mat-fab color="primary" (click)='updateCliente()' aria-label="salva cliente">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </form>
    </div>

</div>