import {trigger, animate, style, group, query, transition} from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
  transition('* <=> *', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })
      , { optional: true }),
    group([
      query(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-in-out', style({ opacity: 1}))
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'rotate3d(0,0,0,0deg) translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'rotate3d(0,1,0,-90deg) translateX(500px)' }))
      ], { optional: true }),
    ])
  ])
]);
