<h2 mat-dialog-title>Ciao {{user.firstName}}</h2>
<p>Per impostare una nuova password è necessario inserirla e poi confermarla qui di seguito.</p>
<div mat-dialog-content>
    <mat-form-field>
        <!-- <mat-label>Inserire la nuova password</mat-label> -->
        <input class="recupero" placeholder="Inserire la nuova password" matInput [(ngModel)]="pass1">
    </mat-form-field>
    <mat-form-field>
        <!-- <mat-label>Ripetere la nuova password</mat-label> -->
        <input class="recupero" placeholder=" Ripetere la nuova password" matInput [(ngModel)]="pass2">
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button mat-button (click)='close()'>Close</button>
    <button mat-icon-button [mat-dialog-close]="pass1" type="button" cdkFocusInitial [disabled]='pass1 == "" && pass1 != pass2'>Ok</button>
</div>