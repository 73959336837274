<ng-container *ngIf="miniSite">
    <div *ngIf="structure" class="guest-services-container" [ngStyle]="{
      'background-image': 'url(' + miniSite.image +')'
    }">
        <div class="text-wrapper">
            <div class="text">
                {{structure.businessName}}
                <div class="divider"></div>
            </div>
        </div>
    </div>
</ng-container>

<div class="grid-wrapper" *ngIf="categories">
    <mat-grid-list cols="2" rowHeight="2:2">
        <ng-container *ngFor="let category of categories">
            <mat-grid-tile [ngStyle]="{
        'color': (category.fgcolor ? category.fgcolor : '#555'),
        'background-image': (category.image ? ('url(' + category.image + ')') : 'none'),
        'background-position': 'center',
        'background-size': 'cover',
        'cursor': 'pointer'
      }" (click)="goToCategory(category)" *ngIf="category.home_visible">
                <div class="title-holder">
                    <div class="title">
                        <div class="title-wrapper">
                            {{category.name | translate: lang }}
                            <div class="divider"></div>
                        </div>

                    </div>
                </div>
            </mat-grid-tile>
        </ng-container>
    </mat-grid-list>
</div>