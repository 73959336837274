import { Structure } from './../interfaces/structure';
// import { DOMAIN } from './../constants/strings';
import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Minisite } from '../interfaces/minisite';
import { CartService } from './cart.service';

@Injectable()
export class StructuresService {
    structureId: string;
    structure: Structure;
    structureSubject = new Subject<Structure>();
    structureChanged$ = this.structureSubject.asObservable();
    miniSite: Minisite;

    constructor(
        private http: HttpClient,
        private cartService: CartService
    ) { }

    /**
   * Imposto lo `structureId` a livello di Service, cosicché possa riutilizzarlo
   * negli altri component in cui questo service è `provided`, riceve come parametro
   * l'ID della struttura
   * @param id
   */
    setId(id: string): void {
        console.log('set struct', id);
        if (id) {
            if (localStorage.getItem(window.btoa('structureId')) != id) {
                this.cartService.wasteCart();
            }
            this.structureId = this.structureId ? localStorage.getItem(window.btoa('structureId')) : id;
            localStorage.setItem(window.btoa('structureId'), id);
        }
    }

    getId(): string | any {
        this.structureId = this.structureId == null ? localStorage.getItem(window.btoa('structureId')) : this.structureId;
        // console.log('registered structure ID is', this.structureId);
        return this.structureId;
    }

    /**
     * Questo metodo crea un `Observable` di una {@link Structure} tramite chiamata HTTP
     * @param id
     */
    getStructure(id: string): Observable<Structure> {
        // console.log('INVOKED WITH ID', id);
        const url = `${environment.DOMAIN}/Strutture/${id}`;
        return this.http.get<Structure>(url);
    }

    getGestori(): Observable<Structure[]> {
        const url = `${environment.DOMAIN}/Strutture/?filter={"where":{"gestore":"true"}}&[fields][id]=true`;
        return this.http.get<Structure[]>(url);
    }

    /**
   * Questa funzione triggera il `Subject` in modo che gli `Observable`
   * sottoscritti a questo evento ricevano il nuovo valore di tipo {@link Structure}
   * @param structure
   */
    setStructure(structure: Structure): void {
        this.structure = structure;
        this.structureSubject.next(structure);
    }

    retrieveStructure(): Structure {
        return this.structure;
    }


    getMinisiteInfos(structureId: string): Observable<Minisite[]> {
        return this.http.get<Minisite[]>(`${environment.DOMAIN}/MiniSiti?filter={"strutturaId": "${structureId}"}`);
    }

}
