import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { StructuresService } from './../../services/structure.service';
import { LanguageService } from './../../services/language.service';
import { Component, OnInit } from '@angular/core';
import { ComplexLabels } from '../../interfaces/complex_labels';
import { RoomOption } from '../../interfaces/room_option';
import { MatSelectChange } from '@angular/material/select';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  templateUrl: './guests-services.component.html',
  styleUrls: ['./guests-services.component.scss']
})
export class GuestsServicesComponent implements OnInit {

  lang: string;
  labels: ComplexLabels;
  loading = false;
  options: RoomOption[] = [
    {
      label: {it: 'Pulizie', en: 'Cleanings'},
      type: 'clean'
    }, {
      label: {it: 'Riparazione', en: 'Repair'},
      type: 'repair'
    }
  ];

  requestForm: FormGroup;

/**
 * Il {@link requestForm} è solo istanziato qui
 */
  constructor(
    private languageService: LanguageService,
    private structureService: StructuresService,
    private fb: FormBuilder,
    private notificationsService: NotificationsService
  ) {
    this.lang = this.languageService.lang;
    this.labels = this.languageService.labels;
    console.log('il linguaggio è', this.lang, this.labels);
    this.requestForm = this.fb.group({
      roomN: [null, Validators.required],
      reservationName: [null, Validators.required],
      requestType: [null, Validators.required],
      extraNotes: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    console.log('this.labels => ', this.labels);
  }

  /**
   * è qui che modifico il {@link requestForm};
   */
  selection(event: MatSelectChange): void {
    const myFormControl: FormControl = this.requestForm.controls.extraNotes as FormControl;
    if (event.value === 'repair') {
      myFormControl.setValidators(Validators.required);
      console.log(myFormControl);
    } else {
      myFormControl.clearValidators();
    }
    this.requestForm.updateValueAndValidity();
  }

  sendRequest(): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.notificationsService.openSnackBar('Request sent');
    }, 1000);
  }


}
