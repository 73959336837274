import { EventEmitter, Inject, Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ComplexLabels } from '../../interfaces/complex_labels';
import { LanguageService } from '../../services/language.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { User } from '../../interfaces/user';
import { UserService } from '../../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
    // registerLabel: TranslationEntry;

    @Input() error: string | null;
    @Input() included: boolean | null;
    @Output() loginEvent = new EventEmitter();
    lang: string;
    labels: ComplexLabels;
    form: FormGroup = new FormGroup({
        username: new FormControl(''),
        password: new FormControl(''),
    });
    registering: any;

    constructor(
        private userService: UserService,
        private languageService: LanguageService,
        public dialog: MatDialog
    ) {
        this.init();
    }

    private async init() {
        this.labels = {} as ComplexLabels;
        await this.recuperoLabel();
        this.lang = this.languageService.lang;
    }

    async recuperoLabel() {
        this.labels = await this.languageService.retrieveLabels2().toPromise();
    }

    async submit() {
        if (this.form.valid) {
            /**
             * va fatto il login cliente
             * e restituito tramite l'event emitter il response dell'operazione
             * se errore login
             * allora valorizzo il valore error
             */
            const U = await this.userService
                .login(this.form.get('username').value, this.form.get('password').value)
                .subscribe(
                    res => console.log('HTTP response', res),
                    (err: HttpErrorResponse) => {
                        console.log('HTTP Error', err);
                        this.error = err.error;
                    },
                    () => {
                        this.loginEvent.emit(this.form.value);
                    }
                );

            // this.submitEM.emit(this.form.value);
        }
    }

    ngOnInit(): void { 
        this.languageService.currentLang.subscribe(lang => this.lang=lang);

    }

    openDialogRegister() {
        const user = {} as User;
        user.userName = this.form.get('username').value;
        user.strutturaId = sessionStorage.structureId;

        const dialogRef = this.dialog.open(UserRegistrationDialog, {
            width: '80vw',
            height: '90vh',
            data: user,
        });

        dialogRef.afterClosed().subscribe({
            next: (out) => {
                console.log('The dialog was closed', out);
                if (out.data) {
                    this.registering = out.data;
                }
            },
            error: (err) => console.log(err),
        });
    }

    openDialogPassRecovery() {
        // tslint:disable-next-line: no-use-before-declare
        const dialogRef = this.dialog.open(PasswordRecoveryDialog, {
            width: '350px',
            data: {
                username: this.form.get('username').value,
                password: '',
                strutturaId: sessionStorage.structureId,
            },
        });
    }
}

// export interface DialogData {
//     username: string;
//     password: string;
//     strutturaId: string;
// }

@Component({
    selector: 'dialog-user-registration',
    templateUrl: 'dialog-user-registration.html',
    styleUrls: ['dialog-user-registration.scss'],
})
export class UserRegistrationDialog {
    constructor(
        public dialogRef: MatDialogRef<UserRegistrationDialog>,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: User
    ) { }

    async regUser() {
        this.data.email = this.data.userName;
        try {
            const out = await this.userService.registerUser(this.data).toPromise();
            this.dialogRef.close({ data: out });
        } catch (error) {
            if (error.error) {
                alert(error.error);
            }
            if (!error.error) {
                alert(JSON.stringify(error));
            }
        }
    }

    close() {
        // this.regUser();
        this.dialogRef.close();
    }
}

@Component({
    selector: 'dialog-password-recovery',
    templateUrl: 'dialog-password-recovery.html',
    styleUrls: ['dialog-password-recovery.scss'],
})
export class PasswordRecoveryDialog {
    showOkMsg = false;
    showFailMsg = false;
    constructor(
        public dialogRef: MatDialogRef<PasswordRecoveryDialog>,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    async callResetRequest() {
        // this.data.email = this.data.userName;
        try {
            console.log(this.data.mailbox);
            const out = await this.userService.resetRequest(this.data.mailbox).toPromise();
            this.showOkMsg = true;
            setTimeout(() => {
                this.dialogRef.close({ data: out });

                this.showOkMsg = false;
            }, 2500);
        } catch (error) {
            // if (error.error) {
            //     alert(JSON.stringify(error.error));
            // }
            // if (!error.error) {
            //     alert(JSON.stringify(error));
            // }
            this.showFailMsg = true;
            setTimeout(() => {
                this.showFailMsg = false;
            }, 2500);
        }
        // console.log('a');
    }

    close() {
        // this.regUser();
        this.dialogRef.close();
    }
}
