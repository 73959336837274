<!-- <app-login-form *ngIf='!logged' [error]="''" (submitEM)='log($event)'></app-login-form> -->
<app-breadcrumb *ngIf='logged' [error]="''" (submitLogout)='logout()'></app-breadcrumb>

<div *ngIf='logged && dataSource' class="orders-container">
    <div class="mat-elevation-z8" *ngIf="( labels$ | async ) as labels">
        <div class="ordini-lista">
            <h3>{{ labels['orders'] | translate:lang }}</h3>
        </div>
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Servizio </th>
                <td mat-cell *matCellDef="let row">
                    <div class="content-box" *ngIf="row.carrello">
                        <img [src]="row.carrello[0].prodotto?.image" class="image_service">
                        <div class="descrizione-servizio">
                            <p class="nome-struttura"> {{row.struttura.businessName}}</p>
                            <p class="data-servizio">{{row.timestamp| date:' dd/MM/yyyy hh:mm'}}</p>
                            <p class="nome-city"> {{ labels['city'] | translate:lang }}</p>
                            <p class="costo-servizio">{{getPrice(row.carrello) | json}} €</p>
                        </div>
                        <div class="bottone-modifica">
                            <button mat-icon-button aria-label="Open" (click)="openOrder(row)">
                                <mat-icon>mode</mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let row"> </td>
            </ng-container>

            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let row"> </td>
            </ng-container>

            <ng-container matColumnDef="carrello">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let row"> </td>
            </ng-container>

            <ng-container matColumnDef="azioni">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let row">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

    <!-- <pre>
        {{allOrders|json}}
        </pre> -->
</div>