import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { Client } from './../interfaces/client';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class ClienteService {

    constructor(private http: HttpClient, private userService: UserService) { }

    getClienteByHash(): Observable<Client[]> {
        const qfilter = {
            where: {
                hash: localStorage.getItem(environment.MY_TOKEN)
            }
        };
        const url = `${environment.DOMAIN}/clienti/?filter=${JSON.stringify(qfilter)}`;
        return this.http.get<Client[]>(url);
    }

    getCliente(): Observable<Client[]> {
        const user = this.userService.getCurrentUser();
        if (!user) {
            return of(null);
        }
        const qfilter = {
            where: {
                userId: user.userId
            },
            include:
                [
                    { relation: 'ordini' }
                ]
        };
        const textFiter = 'filter=' + JSON.stringify(qfilter);

        const url = `${environment.DOMAIN}/clienti/?${textFiter}`;
        return this.http.get<Client[]>(url);
    }

    updateCliente(cliente: Client): Observable<Client> {
        delete cliente['userId'];
        return this.http.patch<Client>(`${environment.DOMAIN}/clienti/?${cliente.id}`, cliente);
    }
}
