<div class="product-container" *ngIf="product">
    <mat-card class="product-card">

        <div class="product" [ngStyle]="{'background-image': 'url(' + product.image + ')'}">
            <div class="description">
                <h3>
                    {{product.name | translate: lang}}
                </h3>
            </div>
        </div>
 
        <mat-card-content *ngIf="purchaseForm">
            <div class="links" *ngIf='labels'>
                {{labels['categories'] | translate: lang}}:
                <span (click)="navigateToCategory(category)" class="category" *ngFor="let category of categories">
                    {{category.name|translate:lang}}
                </span>
            </div>
            <h4 [innerHTML]="product.short_description | translate:lang"></h4>
            <p [innerHTML]="product.description | translate:lang"></p>
            <ng-container *ngIf="product.is_selling">
                <div class="subtotal">
                    <p class="base"> {{labels['base'] | translate: lang}} {{labels['price'] | translate: lang | lowercase}}: {{product.base_price}} € </p>
                    <p class="sconto"> {{labels['discounted'] | translate: lang}} {{labels['price'] | translate: lang | lowercase}}: {{product.special_price}} € </p>
                </div>
                <form [formGroup]="purchaseForm">

                    <fieldset>
                        <legend>
                            <h4>{{labels['booktheservice'] | translate: lang}}</h4>
                        </legend>
                        <table>
                            <tr>
                                <td>Date</td>
                                <td>
                                    <mat-form-field>
                                        <input matInput [matDatepickerFilter]="dateFilter"
                                            [matDatepicker]="myDatepicker" (focus)="myDatepicker.open()" [min]="minDate"
                                            formControlName="reservationDate">
                                        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                                        <mat-datepicker #myDatepicker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>

                            <tr>
                                <td>{{labels[product.is_product ? 'services' : 'persons' ] | translate: lang | titlecase }}</td>
                                <td>
                                    <mat-form-field>
                                        <mat-select (selectionChange)="setAgeFields($event)" formControlName="qty">
                                            <mat-option *ngFor="let qty of product.availabilities" [value]="qty"> {{ qty
                                                }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <!-- {{showVariantiEta}} -->
                            <tr *ngIf="showVariantiEta">
                                <td>Età</td>
                                <td>
                                    <div class="age" formArrayName="people">
                                        <div class="person-age"
                                            *ngFor="let item of getPeople().controls; let i = index;">
                                            <div class="age-input" [formGroup]="item">
                                                <mat-form-field *ngIf="labels">
                                                    <input matInput formControlName="person" type='number'
                                                        [placeholder]="labels['age'] | translate: lang" min="0"
                                                        max="120">
                                                </mat-form-field>
                                                <div class="age" formArrayName="options">
                                                    <div class="person-age"
                                                        *ngFor="let item of getOptions(item).controls; let y = index;">
                                                        <mat-checkbox [formControlName]="y">
                                                            <!-- {{getVariazioniPrezzoCustom()[y] | json }} -->
                                                            {{getVariazioniPrezzoCustom()[y].name }}
                                                            (+{{getVariazioniPrezzoCustom()[y].sconto }} €)
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </table>

                    </fieldset>
                    <div *ngIf="!periodoValido && f.reservationDate">
                        Data non disponibile
                    </div>
                </form>

                <!-- <div>
                    Match sconto:
                </div>
                <div>
                    età => {{priceForAge|json}}
                </div>
                <div>
                    quantità => {{priceForQta|json}}
                </div>
                <div>
                    periodo => {{priceForPeriod|json}}
                </div>
                <div>
                    validità => {{ validita | json }}
                </div>
                <div>
                    periodoValido : {{periodoValido}}
                </div>
                <div>
                    purchaseForm.valid : {{purchaseForm.valid}}
                </div> -->
                <div class="total">
                    {{labels['total'] | translate: lang}}: {{totalPrice}} €
                </div>
                <div>
                    <button (click)="addToCart($event)"
                        [disabled]=" !periodoValido == true || !purchaseForm.valid || totalPrice == 0 "
                        mat-raised-button color="primary" class="purchase-btn">
                        <div *ngIf="cartItem?.id; else addCart">
                            {{labels['updateCart'] | translate: lang}}
                        </div>
                        <ng-template #addCart>
                            {{labels['purchase'] | translate: lang}}
                        </ng-template>
                    </button>
                </div>
            </ng-container>
        </mat-card-content>

    </mat-card>
    <p></p>
</div>