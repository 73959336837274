import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Structure } from '../../../app/interfaces/structure';
import { LanguageService } from '../../../app/services/language.service';
import { NotificationsService } from '../../../app/services/notifications.service';
import { StructuresService } from '../../../app/services/structure.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  lang: any;
  structure: Structure;
  labels$: Observable<any>;
  constructor(
    private structureService: StructuresService,
    private languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  makeCall(siteUrl) {
    window.open("tel:" + siteUrl, '_blank');
    return null;
  }
  
  sendMail() {
    return null;
  }

  ngOnInit(): void {
    // this.lang = this.languageService.lang;
    this.labels$ = this.languageService.retrieveLabels2();
    this.languageService.currentLang.subscribe(lang => this.lang = lang)
    this.notificationsService.setNewCurrentPage({ it: 'Contatti', en: 'Contacts' });
    this.structure = this.structureService.structure;
    this.structureService.structureChanged$
      .subscribe((structure: Structure) => {
        this.structure = structure;
      });
  }

}
