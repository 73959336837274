import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementLocale, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
// import { Person } from '../../interfaces/cart_item';
import { Order } from '../../interfaces/order';
import { Client } from '../../interfaces/client';
import { ClienteService } from '../../services/cliente.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { LanguageService } from '../../services/language.service';
import { NotificationsService } from '../../services/notifications.service';
import { ProductsService } from '../../services/products.service';
import { StructuresService } from '../../services/structure.service';
import { User } from '../../interfaces/user';
import { map } from 'rxjs/operators';
import { MustMatch } from '../../modules/must-match.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ComplexLabels } from '../../interfaces/complex_labels';

class UserObj {
    username: string;
    password: string;
}

@Component({
    selector: 'app-purchase',
    templateUrl: './purchase.component.html',
    styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit {
    logged: boolean;
    currentCliente: User | Client;
    checked = false;
    showSpinner = false;
    @ViewChild(StripeCardComponent) card: StripeCardComponent;

    cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: '#666EE8',
                color: '#31325F',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                    color: '#CFD7E0',
                },
            },
        },
    };
    public lang: StripeElementLocale = 'auto';

    elementsOptions: StripeElementsOptions = {
        locale: this.lang,
    };

    totPrice: number;
    submitted: boolean;
    customerForm = this.fb.group({
        firstName: '',
        lastName: '',
        email: ['', Validators.email],
        mobile: '',
        password01: ['', Validators.required, Validators.minLength(6)],
        password02: ['', Validators.required]
    }, {
        validator: MustMatch('password01', 'password02')
    });
    purchaseForm = this.fb.group({
        checked: ['', [Validators.required]],
    });
    validCC: boolean;
    showGreetings: boolean;
    labels$: Observable<ComplexLabels>;


    constructor(
        private userService: UserService,
        private clientService: ClienteService,
        private fb: FormBuilder,
        private stripeService: StripeService,
        private productsService: ProductsService,
        private structureService: StructuresService,
        //private notificationService: NotificationsService,
        private cartService: CartService,
        private languageService: LanguageService,
        private router: Router
    ) { }

    navigate(param: any): void {
        // this.sidenav.close();
        this.router.navigate([`/${this.structureService.getId()}/${param}`]);
    }

    // async buy(): Promise<void> {
    //     this.showSpinner = true;
    //     this.notificationService.openSnackBar('Invio i dati al server');
    //     const { token, error } = await stripe.createToken(this.card);
    //     this.showSpinner = false;

    //     if (error) {
    //         console.log('Something is wrong:', error);
    //         this.notificationService.openSnackBar('Errore nel recupero del token Stripe');
    //     } else {
    //         this.notificationService.openSnackBar('Token recuperato');


    //         const cits = this.cartService.getItems();

    //         const order: Order = {
    //             carrello: cits,
    //             stripeToken: token,
    //             stripeHistory: {},
    //             contractMode: 'online',
    //             stato: 'aperto',
    //             timestamp: new Date(),
    //             // clienteId: string, // come lo recupero?
    //             strutturaId: this.structureService.getId(),
    //         };
    //         this.productsService.purchaseProduct(order).subscribe((response: Order) => {
    //             console.log('order is ', response);
    //         });
    //     }
    // }

    onChange(ev: StripeCardElementChangeEvent) {
        let displayError = '';
        if (ev.error) {
            displayError = ev.error.message;
            this.validCC = false;
        } else if (ev.complete) {
            this.validCC = true;
        } else {
            displayError = '';
            this.validCC = false;
        }
    }

    loginResponseEvent(_event) {
        console.log(_event);
    }

    async purchase(): Promise<void> {
        if (!this.logged) {
            const customerOK = <User>await this.submitCustomerForm();
            console.log(customerOK);
            if (!customerOK) return;
            this.currentCliente = customerOK;
            const U = await this.userService
                .login(this.currentCliente.email, this.customerForm.controls['password01'].value)
                .subscribe(
                    res => console.log('HTTP response', res),
                    (err: HttpErrorResponse) => {
                        console.log('HTTP Error', err);
                        // this.error = err.error;
                    },
                    // () => {
                    //     this.submitEM.emit(this.form.value);
                    // }
                );
        }
        const name = this.currentCliente.email;
        this.showSpinner = true;

        const cits = this.cartService.getItems();

        this.stripeService.createToken(this.card.element, { name }).subscribe(async (result) => {
            if (result.token) {
                // Use the token
                await this.getCurrentCliente();

                let statoOrdine = 'CONFERMATO';
                /**
                 * 5aab92235143374463371ac3 è il tipo on request
                 */
                if (cits[0].prodotto.tipoprodottoId == '5aab92235143374463371ac3') {
                    statoOrdine = 'DA_CONFERMARE';
                }
                const order: Order = {
                    carrello: cits,
                    stripeToken: result.token.id,
                    stripeHistory: {},
                    contractMode: 'online',
                    stato: statoOrdine,
                    timestamp: new Date(),
                    clienteId: this.currentCliente.id, // '5ce26f3135fa98094282109e', // come lo recupero?
                    strutturaId: this.structureService.getId(),
                };
                this.productsService.purchaseProduct(order)
                    .subscribe(
                        (response: Order) => {
                            this.cartService.wasteCart();
                            this.showSpinner = false;
                            // ordine completato
                            this.showGreetings = true;
                            setTimeout(() => {
                                this.showGreetings = false;
                                this.router.navigate([`/${this.structureService.getId()}/orders`]);
                            }, 3500);
                        },
                        (error) => {
                            console.log(error);
                            alert("somthing goes wrong");
                            this.showSpinner = false;
                        });
            } else if (result.error) {
                // Error creating the token
                console.log(result.error.message);
                this.showSpinner = false;
            }
        });
    }

    async getCurrentCliente() {
        const cli: Client[] = await this.clientService.getCliente().toPromise();
        if (cli && cli.length > 0) {
            this.currentCliente = cli[0];
        } else {
            const cliByHash: Client[] = await this.clientService.getClienteByHash().toPromise();
            if (cliByHash && cliByHash.length > 0) {
                this.currentCliente = cliByHash[0];
                this.f.email.setValue(this.currentCliente.email);
                this.f.firstName.setValue(this.currentCliente.firstName);
                this.f.lastName.setValue(this.currentCliente.lastName);
                this.f.mobile.setValue(this.currentCliente.mobile);
            }
        }
    }

    // convenience getter for easy access to customerForm fields
    get f() { return this.customerForm.controls; }
    get fp() { return this.purchaseForm.controls; }

    async submitCustomerForm(): Promise<User> {
        // this.statusMessage = '';

        // stop here if form is invalid
        if (!this.customerForm.valid) {
            return null;
        }

        const u = {} as User;
        u.email = this.f.email.value;
        u.userName = this.f.email.value;
        u.password = this.f.password01.value;
        u.firstName = this.f.firstName.value;
        u.lastName = this.f.lastName.value;
        u.phone = this.f.mobile.value;
        u.strutturaId = this.structureService.getId();
        return await this.userService.registerUser(u)
            .pipe(map((data) => {
                // if (sId == null) sId = data.strutturaId;
                this.submitted = true;
                // u.id = data.id;
                // console.log(u);
                return data.cliente;
            })).toPromise().catch(error => {
                // console.warn(error);
                this.submitted = false;
                switch (error.status) {
                    case 406:
                        console.warn("challenge errato ... avviso");
                        // this.statusMessage = 'Errore: challenge errato';
                        break;
                    case 412:
                        console.warn("fallito causa precondizione errata");
                        // this.statusMessage = "Errore: challenge o email non corretti."
                        break;
                    default:
                        console.warn("successo qualcosa di imprevisto");
                        // this.statusMessage = "Errore: successo qualcosa di imprevisto"
                        break;
                };
                return null;
            });
    }

    isLogged(): boolean {
        const u = this.userService.getCurrentUser();
        if (u) {
            this.logged = true;
            return true;
        }
        this.logged = false;
        return false;
    }

    logout() {
        this.logged = false;
    }

    async ngOnInit(): Promise<void> {
        this.isLogged();
        this.labels$ = this.languageService.retrieveLabels2();
        this.languageService.currentLang.subscribe(lang => {
            switch (lang) {
                case 'en':
                    this.lang = 'en' as StripeElementLocale;
                    break;
                case 'it':
                    this.lang = 'it' as StripeElementLocale;
                    break;
                case 'es':
                    this.lang = 'es' as StripeElementLocale;
                    break;
                case 'fr':
                    this.lang = 'fr' as StripeElementLocale;
                    break;
                default:
                    this.lang = 'it' as StripeElementLocale;
                    break;
            }
            // console.log(this.lang);
        });
        await this.getCurrentCliente();
        // this.createForm();
        this.totPrice = this.cartService.calcTotalPrice();
    }
}
