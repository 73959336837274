import { Pipe, PipeTransform } from '@angular/core';
import { Attributo } from '../interfaces/attributo';
import { Varianti } from '../interfaces/varianti';
// import { PriceRange } from '../interfaces/price_range';
// import { CategoryProduct } from './../interfaces/category';

@Pipe({ name: 'pricePipe' })
export class PricePipe implements PipeTransform {
    transform(age: number, fasce: Varianti[], special_price: number): Attributo {
        let rightPrice: Attributo;
        if (fasce.length < 1) return null;
        rightPrice = fasce[0].attributi.filter((item: Attributo) => {
            // return ((age >= item.min_age) && (age <= item.max_age));
            return ((age >= item.min_val) && (age <= item.max_val));
        })[0];

        // if (!rightPrice) {
        //     rightPrice = fasce.reduce(function (prev, curr) {
        //         return prev.price < curr.price ? prev : curr;
        //         // return prev.price < curr.price ? prev : curr;
        //     });
        // }
        return rightPrice;
    }
}
