import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StructuresService } from './services/structure.service';
import { UserService } from './services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {

    constructor(public router: Router, private userService: UserService, private structureService: StructuresService) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (!this.userService.getCurrentUser()) {
            const structureId = this.structureService.getId();
            this.router.navigate([`/${structureId}/profile`]);
            return false;
        }
        return true;
    }

}
