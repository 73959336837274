import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { StructuresService } from './../../services/structure.service';
import { LanguageService } from './../../services/language.service';
import { Component, OnInit } from '@angular/core';
import { ComplexLabels } from '../../interfaces/complex_labels';
import { RoomOption } from '../../interfaces/room_option';
import { MatSelectChange } from '@angular/material/select';
import { NotificationsService } from '../../services/notifications.service';
const WEATHER_API_KEY = '0a749a7a53383787bbf77af5fa3e00f7';
@Component({
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {

  lang: string;
  labels: ComplexLabels;

  currentWeatherEntry: any;
  currentTemp: number;

  constructor(
    private languageService: LanguageService,
    private structureService: StructuresService,
    private http: HttpClient
  ) {
    this.lang = this.languageService.lang;
    this.labels = this.languageService.labels;
    console.log('il linguaggio è', this.lang, this.labels);
  }

  ngOnInit(): void {
    console.log('this.labels => ', this.labels);
    this.http.get(`http://api.openweathermap.org/data/2.5/forecast?q=Terni,it&APPID=${WEATHER_API_KEY}`)
    .subscribe((response: any) => {
      for (const entry of response.list) {
        entry.date = new Date(entry.dt_txt);
        entry.temp = Math.floor(entry.main.temp - 273.15);
      }
      console.log('la lista del tempo è', response.list);

      this.currentWeatherEntry = response.list[0];
    });
  }

}
