<mat-sidenav-container class="container" (backdropClick)="close()" [hasBackdrop]="true">
    <mat-sidenav #sidenav mode="over" [(opened)]="opened" (keydown.escape)="close()" disableClose>
        <mat-list role="list" *ngIf="categories">
            <mat-list-item (click)="close()" role="listitem" class="right">
                <button mat-button (click)="close()" style="justify-content: flex-end !important;">
                    <mat-icon aria-hidden="false">close</mat-icon>
                </button>
            </mat-list-item>
            <mat-list-item (click)="navigate('')" role="listitem">
                HOME
            </mat-list-item>
            <ng-container *ngFor="let category of categories">
                <mat-list-item (click)="navigateToCategory(category)" *ngIf="category.menu_visibile" role="listitem">
                    {{category | translatePipe: lang | uppercase }}</mat-list-item>
            </ng-container>
            <mat-divider></mat-divider>
            <mat-list-item (click)="navigate('catalogue')" role="listitem">{{ labels['catalogue'] | translate: lang |
                uppercase }}</mat-list-item>
            <mat-list-item (click)="navigate('how-to-arrive')" role="listitem">{{ labels['howtoarrive'] | translate:
                lang | uppercase }}</mat-list-item>
            <mat-list-item (click)="navigate('contact')" role="listitem">{{ labels['contacts'] | translate: lang |
                uppercase }}</mat-list-item>

            <mat-divider></mat-divider>
            <mat-list-item>{{ labels['mystaymore'] | translate: lang }}</mat-list-item>
            <mat-list role="list" style="margin-left:30px;">
                <mat-list-item (click)="navigate('orders')" role="listitem">{{ labels['orders'] | translate: lang }}
                </mat-list-item>
                <mat-list-item (click)="navigate('profile')" role="listitem">{{ labels['profile'] | translate: lang }}
                </mat-list-item>
            </mat-list>

        </mat-list>
    </mat-sidenav>

    <mat-sidenav-content>
        <main>
            <section>
                <div class="arrows">
                    <!-- <mat-icon *ngIf="!onHome" (click)="back()">
                        chevron_left
                    </mat-icon> -->

                    <mat-icon (click)="sidenav.toggle();" [ngClass]="{'menu-opened': sidenav.opened}">menu</mat-icon>
                </div>

                <div class="title">{{title | translate: lang}}</div>
                <div class="cart">
                    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="lang chooser" style="text-align: right !important;">
                        <img [src]='getCurrentLangFlag().img' style="width: 20px; ; padding-right: 12px;">
                    </button> -->
                    <!-- <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="setLang('it')">
                            <img [src]="getFlag('it').img" style="width: 20px; ; padding-right: 12px;">
                            <span>{{getFlag('it').iso}}</span>
                        </button>
                        <button mat-menu-item  (click)="setLang('en')">
                            <img [src]="getFlag('en').img" style="width: 20px; ; padding-right: 12px;">
                            <span>{{getFlag('en').iso}}</span>
                        </button>
                        <button mat-menu-item  (click)="setLang('fr')">
                            <img [src]="getFlag('fr').img" style="width: 20px; ; padding-right: 12px;">
                            <span>{{getFlag('fr').iso}}</span>
                        </button>
                        <button mat-menu-item  (click)="setLang('es')">
                            <img [src]="getFlag('es').img" style="width: 20px; ; padding-right: 12px;">
                            <span>{{getFlag('es').iso}}</span>
                        </button>
                    </mat-menu> -->
                    <button mat-button (click)="navigate('profile')">
                        <span *ngIf="labels">
                            <span *ngIf="isLogged()"><small>{{ labels['hello'] | translate: lang }}
                                    {{isLogged()}}</small><br><b>{{ labels['profile'] | translate: lang }}</b></span>
                            <span *ngIf="!isLogged()"><b>{{ labels['signin'] | translate: lang }}</b></span>
                        </span>
                    </button>
                    <button mat-button (click)="navigate('cart')">
                        <mat-icon matBadge="{{cartService.totItems}}" matBadgeOverlap="true" matBadgeColor="warn">
                            shopping_cart
                        </mat-icon>
                    </button>

                </div>

            </section>
        </main>

        <section [@routerTransition]="getState(o)">
            <router-outlet #o="outlet"></router-outlet>
        </section>
    </mat-sidenav-content>


</mat-sidenav-container>