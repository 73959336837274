import { StructuresService } from './../../services/structure.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from './../../services/notifications.service';
import { CategoriesService } from './../../services/categories.service';
import { Component, OnInit } from '@angular/core';
import { CategoryProduct } from '../../interfaces/category';
import { LanguageService } from '../../services/language.service';
import { Minisite } from '../../interfaces/minisite';
import { Structure } from '../../interfaces/structure';
import { Product } from '../../interfaces/product';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    categories: CategoryProduct[] = [];
    lang: string;
    structureId: string;
    structure: Structure;
    miniSite: Minisite;

    constructor(
        private categoryService: CategoriesService,
        private notificationsService: NotificationsService,
        private route: ActivatedRoute,
        private languageService: LanguageService,
        private structureService: StructuresService,
        private router: Router
    ) {


        this.structureId = this.route.snapshot.paramMap.get('structureId');
        sessionStorage.structureId = this.structureId;
    }

    ngOnInit() {
        this.languageService.currentLang.subscribe(lang => this.lang=lang);

        this.notificationsService.setNewCurrentPage({ en: 'Home', it: 'Home' });
        
        this.getCats(null);
        this.structureService.structureChanged$.subscribe((x) => {
            this.getCats(x);
        });

        this.structureService.getMinisiteInfos(this.structureId).subscribe(
            (miniSite: Minisite[]) => {
                const myMinisite = miniSite.filter((m: Minisite) => {
                    return m.strutturaId === this.structureId;
                })[0];
                this.miniSite = myMinisite;
            },
            (error) => {
                this.notificationsService.openSnackBar('Errore nel recuperare le info sito');
            }
        );
    }

    getCats(x: Structure) {
        let filter = [];
        if (x) {
            filter = x.storedSearch?.disabledCatsId ?? [];
        }
        const a = this.categoryService.getExcludedId();
        if (a) {
            filter = a;
        }
        this.categoryService.getCategories(this.structureId, filter).subscribe(
            (response: CategoryProduct[]) => {
                this.categories = response.filter( (c: CategoryProduct) => {
                    if (c.prodotti.length > 0) {
                        return true;
                    }
                    return false;
                });
                this.categories = this.categories.map( (obj: CategoryProduct) => {
                    if (obj.categoriaprodottoStrutture.length>0) {
                        obj.image = obj.categoriaprodottoStrutture[0].image;
                        obj.name = obj.categoriaprodottoStrutture[0].nome;
                    }
                    if (obj.image.indexOf("//") != -1) {
                        const items = obj.image.split('/');
                        obj.image = environment.BASE_URL + '/images/' + items[4];
                    } else {
                        // console.log(obj.image);
                        obj.image = environment.BASE_URL + obj.image;
                    }
                    return obj;
                }); 
                this.structure = this.structureService.structure;
                //   console.log('finally set structure', this.structure);
            },
            (error) => {
                this.notificationsService.openSnackBar('Errore nel recuperare le categorie');
            }
        );
    }

    goToCategory(category: CategoryProduct): void {
        this.router.navigate([`${this.structureId}/catalogue/${category.id}`]);
    }

    goToGuestsServices(): void {
        this.router.navigate([`${this.structureId}/guests`]);
    }
}
