import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';

import { QuestionnaireService } from './../../services/questionnaire.service';
import { LanguageService } from './../../services/language.service';
import { NotificationsService } from './../../services/notifications.service';
import { CategoriesService } from './../../services/categories.service';
import { StructuresService } from './../../services/structure.service';
import { Structure } from '../../interfaces/structure';
import { CategoryProduct } from '../../interfaces/category';
import { Location } from '@angular/common';
import { ComplexLabels } from '../../interfaces/complex_labels';
import { TranslationEntry } from '../../interfaces/translation_entry';
import { Observable } from 'rxjs';
// import { Questionnaire } from '../../interfaces/questionnaire';
import { routerTransition } from '../../routing.animations';
import { CartService } from '../../services/cart.service';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    providers: [CategoriesService, StructuresService, NotificationsService],
    animations: [routerTransition],
})
export class MainComponent implements OnInit, AfterViewInit {
    @ViewChild('sidenav') public sidenav: MatSidenav;
    lang: string;
    structureId: string;
    structure: Structure;
    title: TranslationEntry;
    opened = false;
    categories: CategoryProduct[];
    labels: ComplexLabels;
    // questionnaires: Questionnaire[];

    basePath: string;
    onHome = true;

    LANGS = [
        {
            iso: 'it',
            img: 'https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Italy.svg',
            description: 'Italian',
        },
        {
            iso: 'en',
            img: 'https://upload.wikimedia.org/wikipedia/commons/0/0b/English_language.svg',
            description: 'English',
        },
        {
            iso: 'fr',
            img:
                'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_France_%281790%E2%80%931794%29.svg',
            description: 'French',
        },
        {
            iso: 'es',
            img:
                'https://upload.wikimedia.org/wikipedia/commons/b/bf/Simplified_Flag_of_Spain_%28civil_variant%29.svg',
            description: 'Spanish',
        },
        {
            iso: 'de',
            img: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg',
            description: 'German',
        },
        {
            iso: 'zh-CHS',
            img:
                'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg',
            description: 'Simplified Chinese',
        },
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private structureService: StructuresService,
        private userService: UserService,
        private categoryService: CategoriesService,
        private notificationsService: NotificationsService,
        private languageService: LanguageService,
        private _location: Location,
        public cartService: CartService, // private qs: QuestionnaireService
        private titleService: Title
    ) {
        // console.log('MY LOCATION', _location);
    }

    setLang(ln:string) {
        this.languageService.setLang(ln);
        this.lang = this.languageService.lang;
    }

    getCurrentLangFlag(): any {
        const r = this.LANGS.find(x => x.iso === this.lang);
        return r;
    }
    getFlag(iso: string) {
        return this.LANGS.find(x => x.iso === iso);
    }

    getState(outlet) {
        return outlet.activatedRouteData.state;
    }

    close() {
        this.sidenav.close();
    }

    navigate(param: any): void {
        this.sidenav.close();
        this.router.navigate([`/${this.structureId}/${param}`]);
    }

    navigateToCategory(category: CategoryProduct): void {
        // console.log(category.id);
        this.router.navigate([`/${this.structureId}/catalogue/${category.id}`]);
        this.sidenav.close();
    }

    back(): void {
        this._location.back();
    }

    isLogged(): boolean | string {
        const u = this.userService.getCurrentUser();
        if (u) {
            // this.logged = true;
            if (u.firstName) return u.firstName;
            if (u.email) return u.email;
        }
        // this.logged = false;
        return false;
    }

    ngAfterViewInit() {
        this.notificationsService.newPage$.subscribe((newTitle: TranslationEntry) => {
            this.title = newTitle;
        });
    }

    async ngOnInit() {
        // this.lang = this.languageService.lang;
        this.basePath = `/${this.structureId}`;
        this.structureId = this.route.snapshot.paramMap.get('structureId');
        this.structureService.setId(this.structureId);
        this.languageService.currentLang.subscribe(lang => this.lang = lang)

        this.languageService.retrieveLabels2().toPromise().then( async (lang) => {
            this.labels = lang;
            await this.structureService.getStructure(this.structureId).subscribe(
                (response: Structure) => {
                    this.structure = response;
                    this.titleService.setTitle(environment.TITLE + ': ' + this.structure.businessName);
                    this.structureService.setStructure(response);
                    this.categoryService.setExcludedId(response.storedSearch?.disabledCatsId ?? []);
                    this.categoryService
                        .getCategories(this.structureId, response.storedSearch?.disabledCatsId ?? [])
                        .subscribe(
                            (cats: CategoryProduct[]) => {
                                this.categories = cats;
                                for (const obj of this.categories) {
                                    if (obj.categoriaprodottoStrutture.length > 0) {
                                        obj.image = obj.categoriaprodottoStrutture[0].image;
                                        obj.name = obj.categoriaprodottoStrutture[0].nome;
                                    }
                                }
    
                                // this.labels = this.languageService.retrieveLabels();
                            },
                            (error) => {
                                this.notificationsService.openSnackBar(
                                    'Errore nel recuperare le categorie'
                                );
                            }
                        );
                },
                (error) => {
                    console.log(error);
                }
            );
        });



        // this.qs.getAllQuestionnaires().subscribe((qsts: Questionnaire[]) => {
        //     this.questionnaires = qsts;
        // }, err => {
        //     // TODO catch
        //     console.log('ok', err);
        // });

        // this.structureService.setId(this.structureId);

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                if (
                    this._location['_platformStrategy']._platformLocation.location.pathname !==
                    this.basePath
                ) {
                    this.onHome = false;
                } else {
                    this.onHome = true;
                }
            }
        });
    }
}
